import axios from "axios";

export const getLatestMouGeneral = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/latest_mou_general"
      );
      dispatch({
        type: "GET_LATEST_MOU_GENERAL",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getLatestOtherGeneral = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/latest_other_general"
      );
      dispatch({
        type: "GET_LATEST_OTHER_GENERAL",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getLatest = (mou, type, id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/articles/latest_news/" +
          mou +
          "/" +
          type +
          "/" +
          id
      );
      dispatch({
        type: "GET_LATEST_NEWS",
        payload: { data: responseData.data, mou, type, id }
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getWorldStats = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/stats/world"
      );
      dispatch({
        type: "GET_ALL_STATS",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};
