const initialState = {
  current: {},
  error: null,
  logged_in: null,
  loading: true
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, current: action.payload };
    case "SIGNUP":
      return { ...state, current: action.payload };

    case "SET_USER":
      return { ...state, current: action.payload };

    case "LOGGED_IN":
      return { ...state, logged_in: action.payload };
    default:
      return state;
  }
};

export default articles;
