import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import EntriesList from "../Components/EntriesList";
import FlagImg from "../Components/FlagImg";
import LanguagesStats from "../Components/LanguagesStats";
import SearchBar from "../Components/SearchBar";
import SourceTypesStats from "../Components/SourceTypesStats";
import TagStats from "../Components/TagStats";
import { AuthContext } from "../index";
import MainLayout from "../Layout/MainLayout";
import { jurSpecialName } from "../Lib/WMHelper";
import { getLatest } from "../redux/actions/articles";
import {
  getJurisdictions,
  getJurisdictionStats,
  getLanguages,
  getSourceNames,
  getSourceTypes,
  getTags
} from "../redux/actions/related";
import { resetCurrentSearch, setCurrentSearch } from "../redux/actions/search";

const ProfileJur = ({
  match,

  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  loading_tags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  latest,
  getLatest,
  getJurisdictionStats,
  loading_jur_stats
}) => {
  let { slug } = useParams();
  const history = useHistory();

  const [jur, setJur] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if ((!tags || !tags.length) && !loading_tags) getTags();
    if (jur && !loaded) {
      setLoaded(true);
      if (!latest || !latest.mou.jur[jur.id]) getLatest(1, "jur", jur.id);
      if (!latest || !latest.nomou.jur[jur.id]) getLatest(0, "jur", jur.id);
    }
    if (jur && !jur.stats && !loading_jur_stats) {
      console.log("jjjjj", jur.id);
      getJurisdictionStats(jur.id);
    }
  }, [jur]);

  useEffect(() => {
    if ((!jur || !jur.stats) && jurisdictions.length) {
      let jurTmp = jurisdictions.find((t) => t.slug === slug);

      jurTmp = { ...jurTmp, name: jurSpecialName(jurTmp) };

      if (jurTmp) {
        setCurrentSearch({ jurisdictions: [jurTmp] });
        /*if (
          (currentSearch.tags && currentSearch.tags.length > 0) ||
          (currentSearch.languages && currentSearch.languages.length > 0)
        ) {
          setCurrentSearch({
            ...currentSearch,
            jurisdictions: [jurTmp]
          }).then(() => history.push("/search-results"));
        }*/
        setJur(jurTmp);
        setCurrentSearch({ ...currentSearch, jurisdictions: [jurTmp] });
      } else history.push("/home");
    }
  }, [jurisdictions]);

  const Auth = useContext(AuthContext);
  return (
    <MainLayout showControls={true}>
      {" "}
      <SearchBar tags={tags} />
      {jur && jurisdictions.length > 0 && (
        <Fragment>
          <section className="jurisdiction-title">
            <FlagImg
              code={jur.country_code}
              name={jur.name}
              classes="entry__country-flag"
            />
            <h1>{jur.name}</h1>
            <p>Jurisdiction profile</p>
            <hr></hr>
          </section>
          <section className="cards-container">
            <div className="card card--50">
              <h3 className="card__title">Media Outlets</h3>
              <div className="card__content">
                <EntriesList
                  special="jur"
                  isMou={true}
                  jurisdiction={jur}
                  loadedArticles={jur && latest.mou.jur[jur.id]}
                />
              </div>
              {latest.mou.jur[jur.id] !== -1 && (
                <Link
                  to="/search-results"
                  className="card__more"
                  onClick={(e) =>
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: [
                        sourceTypes.find((st) => st.code === "MOU")
                      ]
                    })
                  }
                >
                  Search more entries from media outlets &gt;
                </Link>
              )}
            </div>
            <div className="card card--50">
              <h3 className="card__title">Other Sources</h3>
              <div className="card__content">
                <EntriesList
                  special="jur"
                  isMou={false}
                  jurisdiction={jur}
                  loadedArticles={jur && latest.nomou.jur[jur.id]}
                />
              </div>
              {latest.nomou.jur[jur.id] !== -1 && (
                <Link
                  to="/search-results"
                  className="card__more"
                  onClick={(e) => {
                    console.log(sourceTypes.slice(1));
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: sourceTypes.filter(
                        (st) => st.code !== "MOU"
                      )
                    });
                  }}
                >
                  Search more entries &gt;
                </Link>
              )}
            </div>
            {jur && jur.stats && (
              <Fragment>
                <div className="card card--30 card--45md">
                  <span className="card__subtitle">Top 5</span>
                  <h3 className="card__title card__title--bordered">
                    Entries for {jur.name} by language
                  </h3>
                  {jur.stats.languages && jur.stats.languages.length ? (
                    <div className="card__content">
                      <LanguagesStats
                        gotoSearch={true}
                        setCurrentSearch={setCurrentSearch}
                        currentSearch={currentSearch}
                        languages={jur.stats.languages.map((l) => ({
                          ...languages.find((l2) => l2.id === l.language_id),
                          n_approved: l.n_approved
                        }))}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>

                <div className="card card--40 card--55md">
                  <span className="card__subtitle">Most Reported</span>
                  <h3 className="card__title card__title--bordered">
                    Topics for {jur.name}
                  </h3>
                  {jur.stats.tags.length > 0 && (
                    <div className="card__content">
                      <TagStats
                        slice={40}
                        alwaysReload={true}
                        gotoSearch={true}
                        setCurrentSearch={setCurrentSearch}
                        currentSearch={currentSearch}
                        tags={jur.stats.tags.map((t) => ({
                          ...tags.find((t2) => t2.id === t.tag_id),
                          n_approved: t.n_approved
                        }))}
                      />
                    </div>
                  )}
                  <Link to="/all-topics" className="card__more--small">
                    Search other topics >
                  </Link>
                </div>
                <div className="card card--30 card--100md">
                  <span className="card__subtitle">
                    Non Media-Outlet Sources
                  </span>

                  <h3 className="card__title card__title--bordered">
                    Entries for {jur.name} by source type
                  </h3>
                  {sourceTypes &&
                  sourceTypes.length > 0 &&
                  jur.stats.source_types ? (
                    <div className="card__content">
                      <SourceTypesStats
                        data={jur.stats.source_types.map((st) => ({
                          ...sourceTypes.find(
                            (st2) => st2.id === st.source_type_id
                          ),
                          n_approved: st.n_approved
                        }))}
                        sourceTypes={sourceTypes}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </Fragment>
            )}
          </section>
        </Fragment>
      )}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  latest: state.articles.latest,
  loading_tags: state.related.loading_tags,
  loading_jur_stats: state.related.loading_jur_stats
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getLatest: (mou, type, id) => dispatch(getLatest(mou, type, id)),
  getJurisdictionStats: (jur_id) => dispatch(getJurisdictionStats(jur_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileJur);
