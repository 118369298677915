import React, { useState, useContext, useRef, useEffect } from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";

const Terms = ({ isLoggedIn }) => {
  useEffect(() => {}, []);

  return (
    <MainLayout showControls={isLoggedIn}>
      <section class="cards-container terms-and-conditions">
        <h1 class="terms-and-conditions__title">
          Terms and Conditions - Disclaimers
        </h1>
        <div class="card card--100">
          <div class="card-row">
            <b class="card-row__header">Location of content</b>
            <p class="card-row__text">
              The ILGA World Monitor (IWM) is an automatic source aggregator
              created by ILGA World. The IWM collects online content generated
              from around the world related to issues of diverse sexual
              orientations, gender identities and expressions, and sex
              characteristics (SOGIESC). The IWM collects only the header and
              blurb of each entry and does not store the content of these
              sources, which remains on the websites that generated them. A
              hyperlink to each source is provided so that users can access the
              source if they are interested therein.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Endorsement of content</b>
            <p class="card-row__text">
              ILGA World does not endorse any specific content compiled by the
              IWM. The inclusion of references to the sources compiled in the
              IWM does not represent any position on the part of ILGA World and
              does not imply a value judgment on the quality or reliability of
              the content or the source. Many of the sources surveyed by the IWM
              are known for publishing content that advocates against our
              rights, promotes campaigns that delegitimise and misinform our
              communities, or otherwise promote discord. Additionally, other
              sources without a clear positioning can be vectors for the
              reproduction of hostile, discriminatory and hateful content. The
              IWM compiles some of these sources with the view that they can
              serve as evidence of the high levels of hostility that are
              disseminated through the local and regional press in many parts of
              the world. As a research-for-advocacy tool, including such content
              and data remains valuable even where ILGA World strongly opposes
              the messaging therein.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Trigger warning</b>
            <p class="card-row__text">
              Many media outlets often have very unclear guidelines on explicit
              material, especially when it comes to the inclusion of violent
              photos and videos, or derogatory language. Since the IWM does not
              record the content of the entries, it is not possible to
              anticipate whether any of the collected entries contain content
              that may offend users' sensibilities or negatively impact their
              mental wellbeing. In this sense, this body of text serves as a
              broad trigger warning for all collated content.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Copyright</b>
            <p class="card-row__text">
              ILGA World does not own or claim to own the rights to any of the
              content as systematized in the ILGA World Monitor (IWM). In each
              of the entries, the entity responsible for the publication is
              specified and a hyperlink to the original source is provided. All
              trademarks that appear in the IWM belong to their legitimate
              owners, and all texts, videos and other referenced materials
              belong to their authors or source of publication per local
              regulations or agreements. ILGA World does not store any type of
              content on its servers. In order to ensure that any content
              collected in the IWM is duly attributed to creators and copyright
              owners, each entry has a citation function that allows users to
              quickly and easily copy and paste the citation of the original
              source, duly formatted, with its original title, English
              translation (if applicable), and a hyperlink to access the
              original content.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Comprehensiveness of content</b>
            <p class="card-row__text">
              Despite the fact that the ILGA World Monitor (IWM) collates an
              enormous number of sources every day, ILGA World cannot guarantee
              that absolutely all of the content generated across the world
              which is relevant to our topics of interest will enter our system.
              Given the large number of languages, SOGIESC-related terminology
              and the sheer scale of the internet, the platform - while
              comprehensive - should serve as a complementary tool to any work
              done by users, rather than be taken to represent online SOGIESC
              content in its entirety.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Curation process</b>
            <p class="card-row__text">
              Although the information and content collection process is
              automated, the entries that are publicly displayed have undergone
              a process of human curation and editing so that only entries
              relevant to SOGIESC issues are displayed on the platform. However,
              the curation process carries the risk of human error. It is
              possible that there are errors in identifying the jurisdictions
              relevant to the entry, inaccuracy of labels, and mistakes in the
              nesting of similar content iterations. These values ​​are assigned
              in an automated way by the system and, although they are edited
              during manual processing, some details may be missed. In order for
              our users to report any errors, each entry has a button for that
              purpose.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">English translation</b>
            <p class="card-row__text">
              The system offers translation into English only of the headlines
              of content published in other languages. The translation is
              carried automatically through a service offered by Google
              Translate. Consequently, there are limitations regarding the
              accuracy of translated headlines, especially in languages ​​that
              generate less traffic and demand for the service in general terms.
              In this sense, there may be inaccuracies in the headlines,
              especially in the terminology used to refer to issues of sexual
              orientation and gender identity. In many languages, the
              terminology of the automated application may not yet be optimal,
              and although human effort is made to overcome some inaccuracies,
              users may find translations questionable, prejudiced and
              disrespectful to the dignity of the people they serve. Although
              this is sometimes due to inaccuracies in the automatic translator,
              often the pejorative terminology reflects expressions used by the
              original source, especially in the cases of media outlets which
              are not sensitised or are openly hostile to our communities.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Delayed time processing</b>
            <p class="card-row__text">
              ILGA World cannot guarantee that news or other content will enter
              the IWM in real time. Daily intake of entries can take up to 48
              hours, and the human curation process can add further delay to
              entry approval. On average, new entries are made available to
              users about 72 hours after entering our system. For this reason,
              the IWM is not a tool for searching in real time or for the latest
              “scoops”, but remains useful in providing recent news developments
              and showing trends in media outputs over time.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Entry nesting</b>
            <p class="card-row__text">
              When the system identifies that an entry is a reiteration of
              another already processed, the new entry is nested as a
              “reiteration” of the previous one. This helps to differentiate
              unique entries from those that are mere republications or rewrites
              of an original source. Some nested entries may not be identical
              iterations, but cover the same event in different terms,
              languages, and extensions. Similar entries are nested together
              under a single “parent” entry automatically, and are curated
              during manual processing. Entries 72 hours apart can be nested
              under the same parent entry, as can entries in different
              languages. This nesting process is not without its flaws, but aims
              broadly to limit the repetition of similar content. Users will be
              able to manually expand and display the reiterations of relevant
              entries should they need access to such content.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">
              Sources from websites vulnerable to cyberattacks
            </b>
            <p class="card-row__text">
              Hate groups sometimes digitally target organisations that advocate
              for sexual and gender diversity through cyberattacks targeting
              their websites. There have been cases of sites that have been
              maliciously redirected to pages with pornographic, violent or
              disturbing content. If any of these attacks occur on sites that
              have been surveyed by the ILGA World Monitor (IWM), it is possible
              that users could be exposed to such material. ILGA World cannot be
              held responsible for the inconvenience or harm that these
              situations may generate.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Legality of access to sources</b>
            <p class="card-row__text">
              In some States and territories, mere access to certain sources
              compiled by the IWM or to content on sexual and gender diversity
              may have legal or other consequences for the user. Although ILGA
              World reaffirms its commitment to combat the criminalisation of
              freedom of expression and access to information on matters that
              affect our communities, ILGA World cannot assume the civil,
              criminal or administrative responsibilities derived from these
              regulations.
            </p>
          </div>
        </div>
      </section>

      <section class="cards-container terms-and-conditions">
        <h1 class="terms-and-conditions__title">
          Términos y Condiciones - Descargo de Responsabilidad
        </h1>
        <div class="card card--100">
          <div class="card-row">
            <b class="card-row__header">Ubicación del contenido</b>
            <p class="card-row__text">
              El ILGA World Monitor (IWM) es un agregador automático de fuentes
              creado por ILGA World que recopila entradas generadas a lo largo y
              ancho del mundo cuyo contenido tiene relación con cuestiones de
              diversidad sexual y de género. El ILGA World Monitor (IWM)
              recopila únicamente el encabezado y el lid de cada entrada y no
              almacena el contenido de dichas fuentes, el cual permanece
              íntegramente con el sitio web que la generó. Con este fin, se
              ofrece un hipervínculo para que los usuarios puedan acceder a la
              fuente en caso de estar interesados.{" "}
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Contenido de las fuentes</b>
            <p class="card-row__text">
              ILGA World no avala ningún contenido sustantivo específico
              recopilado por el ILGA World Monitor (IWM). La inclusión de las
              referencias a las fuentes recopiladas en el ILGA World Monitor
              (IWM) no representa posicionamiento alguno por parte de ILGA Mundo
              y no implica juicio de valor sobre la calidad o confiabilidad del
              contenido o de la fuente. Muchas de las fuentes relevadas por el
              ILGA World Monitor (IWM) son conocidas por publicar contenido que
              aboga en contra de nuestros derechos, impulsan campañas que
              deslegitiman y desinforman sobre nuestras comunidades o promueven
              la discordia. Adicionalmente, otras fuentes sin un posicionamiento
              claro pueden ser vectores de reproducción de contenido hostil,
              discriminatorio y odioso. El ILGA World Monitor (IWM) recopila
              algunas de estas fuentes con el objetivo de que puedan servir como
              evidencia de los altos niveles de hostilidad que se difunden a
              través de la prensa local y regional en muchas partes del mundo.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">Advertencia de contenido explícito</b>
            <p class="card-row__text">
              Muchos medios de comunicación suelen tener pautas muy poco claras
              sobre material explícito, sobre todo en lo que se refiere a
              inclusión de fotografías y videos violentos. Dado que el ILGA
              World Monitor (IWM) no registra el contenido de las entradas, no
              es posible anticipar si las entradas recopiladas pueden contener
              imágenes que puedan herir la sensibilidad de los usuarios. En este
              sentido, el presente sirve de advertencia gatillo para todo este
              tipo de contenido.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Derechos de autor</b>
            <p class="card-row__text">
              ILGA World no posee ni pretende poseer los derechos de autor de
              ninguno de los contenidos de las fuentes listadas y sistematizadas
              en el ILGA World Monitor (IWM). En cada una de las entradas se
              especifica la entidad responsable de la publicación y el
              hipervínculo a la fuente original. Todas las marcas comerciales
              que aparecen en el ILGA World Monitor (IWM) pertenecen a sus
              legítimos propietarios, y todos los textos, videos y demás
              materiales referenciados,a sus autores. ILGA World no almacena
              ningún tipo de contenido en sus servidores. A los efectos de
              garantizar que cualquier contenido recopilado en el ILGA World
              Monitor (IWM) sea debidamente atribuido a sus responsables y
              propietarios de derechos de autor, cada entrada cuenta con una
              función de citación que permite fácil y rápidamente a los usuarios
              copiar y pegar la cita a la fuente original debidamente
              formateada, con su titular original y la traducción al inglés (de
              ser el caso), así como el hipervínculo necesario para acceder el
              contenido original.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Integralidad del contenido</b>
            <p class="card-row__text">
              A pesar de que el ILGA World Monitor (IWM) recopila contenidos
              provenientes de una enorme cantidad de fuentes todos los días,
              ILGA World no puede asegurar que absolutamente todas las entradas
              y noticias que se generan en relación con nuestros temas de
              interés a lo largo y ancho del mundo ingresen a nuestro sistema.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Proceso de curación del contenido</b>
            <p class="card-row__text">
              Aunque el proceso de recolección de información y contenidos es
              automatizado, las entradas que se muestran públicamente han
              atravesado un proceso de curación y edición humana con el objetivo
              de permitir el ingreso al sistema únicamente de entradas que sean
              relevantes a temas de diversidad sexual y de género. No obstante,
              el proceso de curación acarrea el riesgo de error humano. Es
              posible que existan errores en la identificación de las
              jurisdicciones relevantes a la entrada, las etiquetas y la
              anidación de reiteraciones. Estos valores son asignados de manera
              automatizada por el sistema y, aunque durante el procesamiento
              manual se agregan y se editan, podrían existir imprecisiones.A los
              efectos de que nuestros usuarios puedan reportar este tipo de
              errores, cada entrada cuenta con un botón para dicho fin.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Traducción al inglés</b>
            <p class="card-row__text">
              El sistema ofrece traducción al inglés únicamente de los titulares
              de los contenidos publicados en otros idiomas. La traducción se
              efectúa de forma automatizada a través del servicio que ofrece
              Google Translate. Consecuentemente, existen limitaciones en cuanto
              a la precisión de los titulares traducidos, especialmente en
              idiomas que generan menos tráfico y demanda del servicio en
              términos generales. En este sentido, pueden existir imprecisiones
              en los titulares, sobre todo en la terminología utilizada para
              referirse a cuestiones de diversidad sexual y de género. En muchos
              idiomas la terminología de la aplicación automatizada puede no ser
              óptima todavía y, si bien se procura poner esfuerzo humano en
              salvar algunas imprecisiones, es posible que les usuarios
              encuentren traducciones cuestionables, prejuiciosas y no
              respetuosas con la dignidad de las personas a las que se refieren.
              Aunque en ocasiones esto se deberá a imprecisiones del traductor
              automático, muchas veces la terminología peyorativa refleja
              expresiones utilizadas por la fuente original, sobre todo en los
              casos de medios de prensa no sensibilizados o abiertamente
              hostiles a nuestras comunidades.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Procesamiento en tiempo diferido</b>
            <p class="card-row__text">
              ILGA World no puede garantizar que las noticias u otros contenidos
              ingresen al ILGA World Monitor (IWM) en tiempo real. El ingreso
              diario de entradas puede demorarse hasta en 48 horas, y el proceso
              de curación humana puede agregar una mayor demora a la aprobación
              de entradas. En promedio, las entradas nuevas se ponen a
              disponibilidad de les usuaries unas 72 horas luego de haber
              ingresado en nuestro sistema. Por ello, el ILGA World Monitor no
              constituye una herramienta para la búsqueda en tiempo real o de
              primicias sobre eventos que se desarrollan al mismo tiempo que se
              efectúa la búsqueda.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Anidación de entradas</b>
            <p class="card-row__text">
              Cuando el sistema identifica que una entrada es la reiteración de
              otra ya procesada, la nueva entrada es anidada como hija de la
              anterior. Esto ayuda a diferenciar entradas únicas de las que son
              meras reiteraciones del mismo contenido por parte de otras fuentes
              recopiladas. Algunas entradas anidadas pueden no ser reiteraciones
              idénticas, sino cubrir el mismo evento en términos, idiomas y
              extensiones diferentes. Las entradas similares se anidan juntas en
              una sola entrada madre de forma automática, y son curadas durante
              el procesamiento manual. Las entradas con 72 horas de diferencia
              pueden quedar anidadas bajo una misma entrada madre, al igual que
              entradas en diferentes idiomas. Este proceso automatizado de
              anidación no está exento de fallas.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">
              Fuentes cuyos sitios web sean atacados
            </b>
            <p class="card-row__text">
              En ocasiones, ciertos grupos de odio atacan digitalmente a
              organizaciones que luchan a favor de la diversidad sexual y de
              género mediante ataques cibernéticos dirigidos contra sus sitios
              web. Han existido casos de sitios que han sido
              malintencionadamente redirigidos a páginas con contenido
              pornográfico, violento o perturbador. Si alguno de estos ataques
              ocurren sobre sitios que hayan sido relevados por el ILGA World
              Monitor (IWM), es posible que los usuarios puedan verse expuestos
              a ese tipo de material. ILGA World no se responsabiliza por las
              molestias que estas situaciones puedan generar.
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">Legalidad del acceso a las fuentes</b>
            <p class="card-row__text">
              En algunos Estados y territorios, el mero acceso a ciertas fuentes
              recopiladas por ILGA World Monitor (IWM) o a contenidos sobre
              diversidad sexual y de género puede acarrear consecuencias legales
              o de otro tipo para el usuario. Aunque ILGA World reafirma su
              compromiso de combatir la criminalización de la libertad de
              expresión y del acceso a la información sobre asuntos que afectan
              a nuestras comunidades, ILGA World no puede asumir las
              responsabilidades civiles, penales o administrativas derivadas de
              estas normas.
            </p>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
