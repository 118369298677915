export const searchGroupBy = {
  st: "Source Type",
  l: "Language",
  c: "Source Location",
  j: "Jurisdiction",
  r: "Region",
  ir: "ILGA Region"
};


export const searchSortBy = {
  re: "Relevance",
  da: "Date Asc.",
  dd: "Date Desc.",
  ja: "Jurisdiction Asc.",
  jd: "Jurisdiction Desc.",
  pd: "Reach"
};