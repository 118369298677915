import React, { useEffect, useState } from "react";
import logo from "../logo.gif";
import "./Search.css";
import axios from "axios";
import Select, { components } from "react-select";
import related from "./Related.json";
import sources from "./Sources.json";

const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]);
  if (values.length > 1) valueLabel = values.length + ` selected...`;

  // Keep standard placeholder and input from react-select
  var childsToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childsToRender}
    </components.ValueContainer>
  );
};

function Search() {
  const [query, setQuery] = useState("");
  const [selects, setSelects] = useState({});
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState("");

  const submitSearch = async (e) => {
    if (query.trim() || Object.keys(selects).length) {
      setLoading(true);
      setRes(
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/demo_routes/search",
          {
            query,
            selects
          }
        )
      );
      setLoading(false);
    }
  };
  const options = related.languages;
  useEffect(() => {
    console.log(selects);
  }, [selects]);

  return (
    <div className="App">
      <header className="App-header">
        <p style={{ color: "white" }}>ILGA WORLD MONITOR</p>
        <form
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
            submitSearch(e);
          }}
          style={{ width: "50%" }}
        >
          <div
            style={{ display: "block", fontSize: "12px", textAlign: "left" }}
          >
            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={related.source_types}
                isMulti={true}
                hideSelectedOptions={false}
                components={{
                  ValueContainer
                }}
                onChange={(e) => setSelects({ ...selects, source_types: e })}
                placeholder="Source Types"
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>

            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={related.tags}
                placeholder="Tags"
                isMulti={true}
                onChange={(e) => setSelects({ ...selects, tags: e })}
                components={{
                  ValueContainer
                }}
                hideSelectedOptions={false}
                getOptionLabel={(option) => `${option.tag}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>
            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={related.jurisdictions}
                isMulti={true}
                placeholder="Jurisdictions"
                components={{
                  ValueContainer
                }}
                onChange={(e) => setSelects({ ...selects, jurisdictions: e })}
                hideSelectedOptions={false}
                getOptionLabel={(option) => `${option.id} - ${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>
            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={related.jurisdictions}
                isMulti={true}
                placeholder="CONs"
                components={{
                  ValueContainer
                }}
                onChange={(e) => setSelects({ ...selects, cons: e })}
                hideSelectedOptions={false}
                getOptionLabel={(option) => `${option.id} - ${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>
            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={options}
                isMulti={true}
                hideSelectedOptions={false}
                placeholder="Languages"
                components={{
                  ValueContainer
                }}
                onChange={(e) => setSelects({ ...selects, languages: e })}
                name="languages"
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>
            <div style={{ display: "inline-block", width: "33%" }}>
              <Select
                options={sources.sources}
                isMulti={true}
                hideSelectedOptions={false}
                placeholder="Sources"
                components={{
                  ValueContainer
                }}
                onChange={(e) => setSelects({ ...selects, sources: e })}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </div>
          </div>
          <div style={{ display: "block" }}>
            <input
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              style={{ height: "50px", width: "80%" }}
            />{" "}
            <input type="submit" value="Search..." style={{ height: "55px" }} />
          </div>
        </form>
        <div style={{ height: "150px", marginTop: "100px", color: "white" }}>
          {loading && (
            <img
              src={logo}
              style={{ height: "150px" }}
              className="App-logo"
              alt="logo"
            />
          )}
          {res && !loading && (
            <div>
              <div>
                <a
                  href={`/api/tmp/search/${res.data.fname}.xlsx`}
                  style={{ color: "yellow" }}
                >
                  Download results
                </a>{" "}
                ({Math.round(res.data.fsize * 100) / 100} mb)
              </div>
              <br />
              <br />
              <div style={{ fontSize: "18px" }}>
                {res.data.amount} results | {res.data.amountD} Dashboard |{" "}
                {res.data.amount - res.data.amountD} Inbox
              </div>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default Search;
