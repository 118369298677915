import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBEihvFMSNuutDQ8S3sOOU_9J9N6IWEyl8",
  authDomain: "wmlogin.naftecbr.com",
  databaseURL: "https://ilga-world-monitor.firebaseio.com",
  projectId: "ilga-world-monitor",
  storageBucket: "ilga-world-monitor.appspot.com",
  messagingSenderId: "625584024668",
  appId: "1:625584024668:web:b34f32a186a7f35de78a99",
  measurementId: "G-C02B1PQM15"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth, firebase };
