import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }

  axios.interceptors.response.use(
    (response) => {
      if (response.config.parse) {
        //perform the manipulation here and change the response object
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 403)
        console.log("should logout logout()");
      return Promise.reject(error);
    }
  );
};

export default setAuthToken;
