const initialState = {
  languages: [],
  source_types: [],
  last_updated: null,
  source_statuses: [],
  syntax_types: [],
  jurisdictions: [],
  regions: [],
  language_syntaxes: [],
  source_names: [],
  tags: [],
  alert_subaccounts: [],
  error: null,
  loading: true,
  loading_tags: false,
  loading_jur_stats: false,
  loading_tag_stats: false,
  report_reasons: []
};

const related = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REPORT_REASONS":
      return { ...state, report_reasons: action.payload };
    case "GET_LANGUAGES":
      return { ...state, languages: action.payload };
    case "GET_SOURCES_NAMES":
      return { ...state, source_names: action.payload };
    case "GET_SOURCE_TYPES":
      return {
        ...state,
        source_types: action.payload.source_types,
        last_updated: action.payload.last_updated
      };
    case "GET_SOURCE_STATUSES":
      return { ...state, source_statuses: action.payload };
    case "GET_JURISDICTIONS":
      return { ...state, jurisdictions: action.payload };
    case "GET_JURISDICTIONS_STATS":
      return {
        ...state,
        jurisdictions: state.jurisdictions.map((j) =>
          j.id === action.payload.jurisdiction_id
            ? { ...j, stats: action.payload.stats }
            : j
        )
      };
    case "GET_TAG_STATS":
      return {
        ...state,
        tags: state.tags.map((j) =>
          j.id === action.payload.tag_id
            ? { ...j, stats: action.payload.stats }
            : j
        )
      };
    case "GET_LANGUAGE_STATS":
      return {
        ...state,
        languages: state.languages.map((j) =>
          j.id === action.payload.language_id
            ? { ...j, stats: action.payload.stats }
            : j
        )
      };
    case "GET_REGIONS":
      return { ...state, regions: action.payload };
    case "GET_TAGS":
      return { ...state, tags: action.payload };
    case "GET_SYNTAX_TYPES":
      return { ...state, syntax_types: action.payload };
    case "GET_ALERT_SUBACCOUNTS":
      return { ...state, alert_subaccounts: action.payload };
    case "GET_LANGUAGE_SYNTAXES":
      return { ...state, language_syntaxes: action.payload };
    case "LOADING_RELATED":
      return { ...state, loading: action.payload };
    case "LOADING_TAGS":
      return { ...state, loading_tags: action.payload };
    case "LOADING_JUR_STATS":
      return { ...state, loading_jur_stats: action.payload };

    case "LOADING_TAG_STATS":
      return { ...state, loading_tag_stats: action.payload };

    case "LOADING_LANGUAGE_STATS":
      return { ...state, loading_language_stats: action.payload };

    default:
      return state;
  }
};

export default related;
