import React, { useState, useContext, useRef, useEffect } from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";

const ContactUs = ({ isLoggedIn }) => {
  useEffect(() => {}, []);

  return (
    <MainLayout showControls={isLoggedIn}>
      <section class="cards-container contact-page split-bg">
        <form class="card card--60 contact-form">
          <h2 class="contact-form__title">Contact</h2>
          <input class="contact-form__input" type="text" placeholder="Name" />
          <input
            class="contact-form__input"
            type="text"
            placeholder="Last name"
          />
          <input class="contact-form__input" type="text" placeholder="Mail" />
          <textarea
            class="contact-form__textarea"
            placeholder="Text"
          ></textarea>
          <input class="contact-form__submit" type="submit" value="Send" />
        </form>

        <div class="contact-page__info">
          <b>ilga world</b>
          <p>+41 22 7313254</p>
          <p>info@ilga.org</p>
          <p>5th Floor – 20 Rue Rothschild 1202 Geneva Switzerland</p>
        </div>
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
