import React, { useState, useContext, useRef, useEffect } from "react";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import "./CountriesStats.css";
import FlagImg from "./FlagImg";
import { Link, useHistory } from "react-router-dom";
import { ths } from "../Lib/WMHelper";
/*
+
                onMouseEnter={(e) => {
                  tmp = d3.select(
                    "#" + currentCountry.country_code.toLowerCase()
                  );
                  if (tmp) {
                    color = tmp.style("fill");
                    tmp && tmp.style("fill", "#ffd166");
                  }
                }}
                onMouseLeave={(e) => {
                  tmp = d3.select(
                    "#" + currentCountry.country_code.toLowerCase()
                  );
                  if (tmp) {
                    tmp && tmp.style("fill", color);
                  }
                }}
*/

const CountriesStats = ({
  countries,
  gotoSearch,
  currentSearch,
  setCurrentSearch
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="countries-stats">
        <ul className="countries-stats-list">
          {countries.map((currentCountry) => (
            <li className="countries-stats-list__item">
              <Link
                to={"#!"}
                onClick={(e) => {
                  return currentCountry.n_approved
                    ? gotoSearch
                      ? setCurrentSearch({
                          ...currentSearch,
                          jurisdictions: [currentCountry]
                        }).then(() => history.push("/search-results"))
                      : history.push("/jurisdictions/" + currentCountry.slug)
                    : e.preventDefault();
                }}
              >
                <FlagImg
                  classes="entry__country-flag"
                  code={currentCountry.country_code}
                  name={currentCountry.name}
                />

                <p>
                  {currentCountry.id === "DT-11"
                    ? "Falk./Malv. (UK/Arg.)"
                    : currentCountry.id === "DT-5"
                    ? "Bonaire, Saba and S.E. (NL)"
                    : currentCountry.id === "N-3"
                    ? "Sahrawi A.D.R."
                    : currentCountry.id === "DT-34"
                    ? "St. Helena, A. & T.A. (UK)"
                    : currentCountry.id === "DT-6"
                    ? "British Indian Oc. Terr. (UK)"
                    : currentCountry.name}
                </p>
                <b>{ths(currentCountry.n_approved)}</b>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CountriesStats;
/*
onMouseEnter={(e) => {
                  if (
                    e.target.id.slice(4) &&
                    d3.select("#" + e.target.id.slice(4))
                  ) {
                    color = d3.select("#" + e.target.id.slice(4))
                      ? d3.select("#" + e.target.id.slice(4)).style("fill")
                      : "";
                    d3.select("#" + e.target.id.slice(4)).style(
                      "fill",
                      "lightgreen"
                    );
                  }
                }}
                onMouseLeave={(e) => {
                  e.target.id.slice(4) &&
                    d3.select("#" + e.target.id.slice(4)) &&
                    d3.select("#" + e.target.id.slice(4)).style("fill", color);
                }}
*/
