const initialState = {
  latest_mou_general: [],
  latest_other_general: [],
  latest: {
    mou: { jur: {}, tag: {}, language: {} },
    nomou: { jur: {}, tag: {}, language: {} }
  },
  stats: {
    all: 0,
    world: {
      regions: {},
      countries: {}
    }
  },
  error: null,
  loading: true
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LATEST_MOU_GENERAL":
      return { ...state, latest_mou_general: action.payload };
    case "GET_LATEST_OTHER_GENERAL":
      return { ...state, latest_other_general: action.payload };
    case "GET_LATEST_NEWS":
      return {
        ...state,
        latest: {
          ...state.latest,
          ...(action.payload.mou === 1
            ? {
                mou: {
                  ...state.latest.mou,
                  [action.payload.type]: {
                    ...state.latest.mou[action.payload.type],
                    [action.payload.id]: action.payload.data.length
                      ? action.payload.data
                      : -1
                  }
                }
              }
            : {
                nomou: {
                  ...state.latest.nomou,
                  [action.payload.type]: {
                    ...state.latest.nomou[action.payload.type],
                    [action.payload.id]: action.payload.data.length
                      ? action.payload.data
                      : -1
                  }
                }
              })
        }
      };

    case "GET_ALL_STATS":
      console.log(action.payload);
      return { ...state, stats: { ...state.stats, all: action.payload.all } };

    case "ERROR_ARTICLES":
      return { ...state, error: action.payload };

    case "LOADING_ARTICLES":
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default articles;
