import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import { utc } from "moment-timezone";
import { Fragment } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

const SharePopup = ({ isOpen, setIsOpen, articles, user }) => {
  const [share, setShare] = useState("");

  const [errors, setErrors] = useState({});
  const [saved, setSaved] = useState(false);

  const [error, setError] = useState("");

  const [message, setMessage] = useState("");

  const [shareArticles, setShareArticles] = useState([]);

  const recaptchaRef = React.createRef();
  const history = useHistory();

  let btnRef = useRef();
  const Auth = useContext(AuthContext);
  function closeModal() {
    setSaved(false);
    setMessage("");
    setError("");
    setIsOpen(false);
  }

  useEffect(() => {
    console.log(isOpen);
    if (isOpen && isOpen.h) {
      setShareArticles([isOpen]);
    } else if (isOpen === true) {
      setShareArticles(articles);
    }
  }, [isOpen]);

  useEffect(() => {
    if (articles && articles.length) {
      setShareArticles(articles);
    }
  }, [articles]);

  const [contacts, setContacts] = useState(user.sharing_emails || []);

  const { name, email, body, subject, copy_user } = share;

  const handleForm = async (e) => {
    e.preventDefault();
    let shouldSend = true;
    if (!email || !name) {
      setError("E-mail and name are required");
      setMessage("");
      shouldSend = false;
    } else if (
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
        email
      ) === false
    ) {
      setError("Invalid e-mail");
      setMessage("");
      shouldSend = false;
    }
    if (shouldSend) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.innerHTML = "Sending...";
      }
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/articles/shareArticles",
          {
            shareArticles,
            name,
            email,
            body,
            subject,
            copy_user
          }
        );
        btnRef.current.removeAttribute("disabled");
        setError("");
        btnRef.current.innerHTML = "SHARE";
        setMessage(
          <div style={{ textAlign: "center" }}>
            An email has been sent to {name}.<br />
            <br />
            If this is the first time you share content with {name}, they may
            need to check their junk inbox.
          </div>
        );
        setShare({ ...share, name: "", email: "" });
      } catch (e) {
        btnRef.current.innerHTML = "There was an error...";
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div
        className={
          "popup-box" + (message ? " popup-box-med" : " popup-box-med")
        }
      >
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>

        {!message && (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/forward_to_inbox.svg" height="48px" />
            </p>
            <h3 className="popup-box__subtitle">
              Share{" "}
              {shareArticles
                ? shareArticles.length === 1
                  ? "this entry from " + shareArticles[0].s
                  : shareArticles.length + " entries"
                : ""}
            </h3>
            <p style={{ marginTop: "1.6rem", fontSize: "1.5rem", textAlign:"center" }}>
              Recipient will receive an email with a copy of the entry and will
              not need to access the ILGA World Monitor
            </p>
          </Fragment>
        )}
        {!message ? (
          <form className="save-search-criteria-form">
            {error && <label className="message-error">{error}</label>}
            <span>Recipient's E-mail</span>

            <input
              className="save-search-criteria-form__input"
              value={email}
              onChange={(e) => setShare({ ...share, email: e.target.value })}
              name="email"
              type="text"
              placeholder="E-mail address"
            />

            <span>Recipient's Name</span>

            <input
              className="save-search-criteria-form__input"
              value={name}
              onChange={(e) => setShare({ ...share, name: e.target.value })}
              name="name"
              type="text"
              placeholder="Recipient's name "
            />

            <span>Subject (leave empty to send default subject)</span>

            <input
              className="save-search-criteria-form__input"
              value={subject}
              onChange={(e) => setShare({ ...share, subject: e.target.value })}
              name="name"
              type="text"
              placeholder={
                shareArticles.length === 1
                  ? user.firstname +
                    " " +
                    user.lastname +
                    " is sharing this entry from the ILGA World Monitor with you"
                  : user.firstname +
                    " " +
                    user.lastname +
                    " is sharing " +
                    shareArticles.length +
                    " entries from the ILGA World Monitor with you"
              }
            />

            <span>Intro text (leave empty to send default text)</span>

            <textarea
              className="save-search-criteria-form__input"
              value={body}
              onChange={(e) => setShare({ ...share, body: e.target.value })}
              name="body"
              type="text"
              placeholder={
                "Hey " +
                (name ? name : "[RECIPIENT]") +
                ",\n\nI found this on the ILGA World Monitor and I believe it may be of your interest."
              }
              rows="5"
            />

            <div className="popup-save-radio">
              <input
                type="checkbox"
                name="copy_user"
                checked={copy_user || false}
                onChange={(e) =>
                  setShare({ ...share, copy_user: e.target.checked })
                }
              />{" "}
              Send a copy to my email account
            </div>

            <br />
            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={handleForm}
            >
              Share
            </button>
            {errors.message && (
              <div className="login-form-error">{errors.message} </div>
            )}
          </form>
        ) : (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/check_circle.svg" height="48px" />
            </p>
            <h3 className="popup-box__subtitle">Success!</h3>
            <p className="popup-box__subtitle" style={{ marginTop: "20px" }}>
              {message}
            </p>
            <br />
            <br />
            <button
              className="login-form__submit"
              style={{ backgroundColor: "#4D6A85" }}
              onClick={(e) => setMessage("")}
            >
              Share with someone else?
            </button>
            <button className="login-form__submit" onClick={closeModal}>
              Close
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SharePopup;
