import axios from "axios";

export const login = (g_uid) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        {
          g_uid
        }
      );

      dispatch({ type: "LOGIN", payload: responseData.data });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const setUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      dispatch({ type: "SET_USER", payload: user });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const setLoggedIn = (logged) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      dispatch({ type: "LOGGED_IN", payload: logged });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const updateUser = (user, type) => {
  return async (dispatch) => {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/users/update_user",
      { user, type }
    );
    if (responseData.data.error) {
      throw new Error(responseData.data.error);
    }
    if (type !== "email")
      dispatch({ type: "SET_USER", payload: responseData.data });
    dispatch({ type: "LOADING_RELATED", payload: false });
  };
};
