import React, { Component, useEffect } from "react";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import MainLayout from "./Layout/MainLayout";
import PublicHomeLayout from "./Layout/PublicHomeLayout";

// This function takes a component...
function RequireAuth(WrappedComponent, isLoggedIn) {
  // ...and returns another component...
  return class extends React.Component {
    componentDidMount() {
      console.log(this.props);
      // ... that takes care of the subscription...
    }

    componentWillUnmount() {}

    handleChange() {}

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      if (isLoggedIn === false) {
        return (
          <Redirect
            push
            to={{
              pathname: "/",
              state: {
                openLogin: true,
                original_route: this.props.location.pathname
              }
            }}
          />
        );
      }
      //, state: "openLogin"
      else if (isLoggedIn === true) return <WrappedComponent />;
      else
        return (
          <MainLayout>
            <div>
              {" "}
              <div class="LoadingBar">
                <div class="LoadingBar-progress"></div>
              </div>
            </div>
          </MainLayout>
        );
    }
  };
}

export default RequireAuth;
