/**
 * src/App.js
 */
import React from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  
} from "react-router-dom";

import routes from "./routes";
import RequireAuth from "./RequireAuth";
import ScrollToTop from "./Lib/ScrollToTop";
import { connect } from "react-redux";
import PublicHome from "Pages/PublicHome";

function Main({ isLoggedIn }) {
  

  return (
    <div className="Main">
      <Router>
        <ScrollToTop />
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={
                route.admin
                  ? RequireAuth(route.main, isLoggedIn)
                  : route.main
              }
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
