import React, { useState, useContext, useRef, useEffect } from "react";

import MainLayout from "../Layout/MainLayout";
import { AuthContext } from "../index";

import "./Dashboard.css";
import { withRouter } from "react-router-dom";
import SearchBar from "../Components/SearchBar";
import SvgMap from "../Components/SvgMap";
import EntriesList from "../Components/EntriesList";
import LanguagesStats from "../Components/LanguagesStats";
import TagStats from "../Components/TagStats";
import CountriesStats from "../Components/CountriesStats";
import SourceTypesStats from "../Components/SourceTypesStats";
import { connect } from "react-redux";
import {
  getJurisdictions,
  getLanguages,
  getSourceNames,
  getSourceTypes,
  getTags
} from "../redux/actions/related";
import { resetCurrentSearch, setCurrentSearch } from "../redux/actions/search";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { ths } from "../Lib/WMHelper";
import { utc } from "moment-timezone";

const getTagStats = (tags) => {
  let colors = [
    "#FFB6C1",
    "#AFEEEE",
    "#B8860B",
    "#B0C4DE",
    "#DCDCDC",
    "#C0C0C0",
    "#FFE4B5",
    "#7CFC00",
    "#808000",
    "#9400D3",
    "#3CB371",
    "#BC8F8F",
    "#DA70D6",
    "#778899",
    "#DDA0DD",
    "#9370DB",
    "#7CFC00",
    "#808000",
    "#9400D3",
    "#3CB371",
    "#BC8F8F",
    "#DA70D6",
    "#778899",
    "#DDA0DD",
    "#9370DB"
  ];

  let total = tags.reduce((total, obj) => total + obj.n_approved, 0);
  let official = tags
    .filter((t) => [33, 32, 77, 126, 50, 8, 139, 140].indexOf(t.id) === -1)
    .map((l, i) => ({
      id: l.name,
      text: l.name,
      value: Math.round(l.n_approved),
      color: colors[1]
    }))
    .sort((a, b) => b.value - a.value)
    //.slice(0, 30)
    .map((l, i) => ({
      ...l,
      color: colors[i]
    }));

  return official;
};

const Dashboard = ({
  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  loading_tags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  user,
  lastUpdated,
  location
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [tagStats, setTagStats] = useState([]);
  useEffect(() => {
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!sourceTypes || !sourceTypes.length) getSourceTypes();
    if (!languages || !languages.length) getLanguages();
    if ((!tags || !tags.length) && !loading_tags)
      getTags().then(() => {
        console.log(tags);
        setTagStats(tags);
      });
  }, []);

  useEffect(() => {
    console.log(location);
    resetCurrentSearch();
  }, [location.pathname]);

  return (
    <MainLayout showControls={true}>
      {" "}
      <SearchBar tags={tags} />
      {user && (
        <section className="greeting">
          <div className="greeting__text">
            <h1>
              Welcome, <span>{user.firstname}</span>!
            </h1>
          </div>
          <div className="greeting__nav">
            <Link to="/my-folders" title="My Folders">
              <span className="greeting__link greeting__link--folders"></span>My
              Folders
            </Link>{" "}
            |
            <Link to="/saved-searches">
              <span className="greeting__link greeting__link--criteria"></span>
              My Saved Search Criteria
            </Link>
          </div>
        </section>
      )}
      <section className="cards-container">
        <div className="card card--50">
          <div>
            <h3 style={{ float: "left" }} className="card__title">
              Media Outlets{" "}
            </h3>
            {lastUpdated && (
              <div style={{ float: "right" }}>
                Last updated: {utc(lastUpdated).format("DD MMM YYYY")}
              </div>
            )}
          </div>
          <div className="card__content" style={{ paddingTop: "35px" }}>
            <EntriesList special="general" isMou={true} />
          </div>
          <Link
            to="/search-results"
            className="card__more"
            onClick={(e) =>
              setCurrentSearch({
                ...currentSearch,
                source_types: [sourceTypes.find((st) => st.code === "MOU")]
              })
            }
          >
            Search more entries from media outlets &gt;
          </Link>
        </div>
        <div className="card card--50">
          <div>
            <h3 style={{ float: "left" }} className="card__title">
              Other Sources{" "}
            </h3>
            {lastUpdated && (
              <div style={{ float: "right" }}>
                Last updated: {utc(lastUpdated).format("DD MMM YYYY")}
              </div>
            )}
          </div>
          <div className="card__content" style={{ paddingTop: "35px" }}>
            <EntriesList special="general" isMou={false} />
          </div>
          <Link
            to="/search-results"
            className="card__more"
            onClick={(e) => {
              setCurrentSearch({
                ...currentSearch,
                source_types: sourceTypes.filter((st) => st.code !== "MOU")
              });
            }}
          >
            Search more entries &gt;
          </Link>
        </div>
        <div className="card card--55">
          <h3 className="card__title">Explore entries by jurisdiction</h3>
          <div className="card__content card__content">
            <SvgMap countries={jurisdictions} setMainMapLoaded={setMapLoaded} />
          </div>
        </div>
        <div className="card card--45">
          <h3 className="card__title">Total entries by jurisdiction</h3>
          <div className="card__content card__content">
            <CountriesStats countries={jurisdictions} />
          </div>
        </div>
        <Fragment>
          <div className="card card--30 card--45md">
            <span className="card__subtitle">Top 5</span>
            <h3 className="card__title card__title--bordered">Languages</h3>
            {mapLoaded && languages && languages.length ? (
              <div className="card__content">
                <LanguagesStats
                  gotoSearch={false}
                  setCurrentSearch={setCurrentSearch}
                  currentSearch={currentSearch}
                  languages={languages}
                />
              </div>
            ) : (
              <span></span>
            )}
          </div>

          <div className="card card--40 card--55md">
            <span className="card__subtitle">Most Reported</span>
            <h3 className="card__title card__title--bordered">Topics</h3>
            {(true || tags.length) && (
              <div className="card__content">
                <TagStats tags={tags.slice(0, 35)} />
              </div>
            )}
            <Link to="/all-topics" className="card__more--small">
              Search other topics >
            </Link>
          </div>
          <div className="card card--30 card--100md">
            <span className="card__subtitle">Non Media-Outlet Sources</span>

            <h3 className="card__title card__title--bordered">Source Type</h3>
            {mapLoaded && sourceTypes && sourceTypes.length ? (
              <div className="card__content">
                <SourceTypesStats
                  data={sourceTypes}
                  sourceTypes={sourceTypes}
                />
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </Fragment>
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  lastUpdated: state.related.last_updated,
  tags: state.related.tags,
  user: state.users.current,
  loading_tags: state.related.loading_tags
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
