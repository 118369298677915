import React, { useState, useContext, useRef, useEffect } from "react";
import { utc } from "moment-timezone";
import FlagImg from "./FlagImg";
import { useHistory } from "react-router-dom";
import { citeOne } from "../Lib/Cite";

const ChildEntry = ({
  article,
  sourceTypes,
  addArticlesSelected,
  removeArticlesSelected,
  setArticlesSelected,
  setSharePopupOpen,
  reportArticle,
  setReportArticle,
  jurisdictions,
  setAlert,
  saveFolderPopupOpen,
  setSaveFolderPopupOpen,
  articlesSelected
}) => {
  const history = useHistory();
  return (
    <div className="child-entry">
      <input
        type="checkbox"
        id=""
        value="1"
        className="entry__checkbox"
        checked={articlesSelected.find((a) => a.id === article.id)}
        onClick={(e) =>
          e.target.checked
            ? addArticlesSelected(article)
            : removeArticlesSelected(article)
        }
      />
      <div className="child-entry__col">
        <p className="entry__date">{utc(article.da).format("DD MMM YYYY")}</p>
        <h3
          className="entry__title entry__title--margin"
          onClick={(e) => {
            if (article.l !== "English")
              window.open(
                "http://translate.google.com/translate?js=n&sl=auto&tl=en&u=" +
                  article.u,
                "_blank",
                "noopener,noreferrer"
              );
            else {
              window.open(article.u, "_blank", "noopener,noreferrer");
            }
          }}
        >
          {article.th && article.th !== article.h ? article.th : article.h}
        </h3>
        {article.th && article.th !== article.h && (
          <h4
            className="entry__title entry__title--translation"
            onClick={(e) => {
              window.open(article.u, "_blank", "noopener,noreferrer");
            }}
          >
            {article.h}
          </h4>
        )}
        <p className="entry__content">{article.d}</p>
      </div>
      <div className="child-entry__col">
        <p className="entry__meta entry__meta--italic">
          {article.s} |{" "}
          <span
            className="entry__meta--highlighted"
            style={{ fontStyle: "normal" }}
          >
            {sourceTypes.find((st) => st.name === article.st).code}
          </span>
        </p>
        <p className="entry__meta entry__meta--flex">
          <FlagImg
            classes="entry__country-flag--small"
            code={article.c}
            name={article.c}
          />{" "}
          <span className="entry__meta--bold">{article.c}</span>&nbsp; - {article.l}
        </p>
      </div>
      <div className="child-entry__col child-entry__col--actions">
        <button
          className="entry__action entry__action--share"
          onClick={async (e) => {
            setArticlesSelected([]);
            setSharePopupOpen(article);
          }}
        ></button>
        <button
          className="entry__action entry__action--save"
          onClick={async (e) => {
            setArticlesSelected([]);
            setSaveFolderPopupOpen(article);
          }}
        ></button>
        <button
          className="entry__action entry__action--cite"
          onClick={() =>
            citeOne(article, jurisdictions).then((ret) =>
              setAlert(
                "<strong>" +
                  article.s +
                  "'s</strong> article info copied to clipboard"
              )
            )
          }
        ></button>
        <button
          className="entry__action entry__action--report"
          onClick={() => setReportArticle(article)}
        ></button>
      </div>
    </div>
  );
};
export default ChildEntry;
