import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  Fragment
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { setLoggedIn, setUser } from "../redux/actions/users";
import { connect } from "react-redux";

const SignupForm = ({
  isOpen,
  setIsOpen,
  isLoggedIn,
  userStore,
  setUserStore,
  setLoggedIn
}) => {
  const [user, setUser] = useState("");
  const [success, setSuccess] = useState("");

  const [errors, setErrors] = useState("");
  const recaptchaRef = React.createRef();
  const history = useHistory();

  let btnRef = useRef();
  const Auth = useContext(AuthContext);
  const handleForm = async (e) => {
    e.preventDefault();
    if (
      !user.pronoun ||
      !user.firstname ||
      !user.lastname ||
      !user.email ||
      !user.password
    ) {
      setErrors("Please complete all fields");
      return;
    }
    if (user.password !== user.password2) {
      setErrors("Passwords doesn't match");
      return;
    }

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      btnRef.current.setAttribute("value", "Registering...");
    }

    const token = await recaptchaRef.current.executeAsync();

    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/users/checkCaptcha",
      {
        token
      }
    );

    if (!res.data.success) return;
    else recaptchaRef.current.reset();

    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(async (res) => {
        const token = await auth?.currentUser?.getIdToken(true);
        if (res.user) {
          await res.user.updateProfile({
            displayName: user.firstname + " " + user.lastname
          });
          res = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/users/signup",
            {
              g_uid: auth?.currentUser?.uid,
              firstname: user.firstname,
              lastname: user.lastname,
              pronoun: user.pronoun,
              email: user.email
            }
          );

          setSuccess(true);
        }
      })
      .catch((e) => {
        btnRef.current.removeAttribute("disabled");
        setErrors(e.message);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="popup-box">
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>
        <h2 className="popup-box__title">ilga world monitor</h2>
        {!success ? (
          <Fragment>
            <h3 className="popup-box__subtitle">Register</h3>
            <form onSubmit={(e) => handleForm(e)} className="login-form">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />

              <input
                className="register-form__input"
                type="text"
                placeholder="Pronoun"
                value={user.pronoun}
                onChange={(e) => setUser({ ...user, pronoun: e.target.value })}
              />
              <input
                className="register-form__input"
                type="text"
                placeholder="First Name"
                value={user.firstname}
                onChange={(e) =>
                  setUser({ ...user, firstname: e.target.value })
                }
              />
              <input
                className="register-form__input"
                type="text"
                placeholder="Last Name"
                value={user.lastname}
                onChange={(e) => setUser({ ...user, lastname: e.target.value })}
              />
              <input
                className="register-form__input"
                type="text"
                placeholder="E-mail Address"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              <input
                className="register-form__input"
                type="password"
                placeholder="Password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />

              <input
                className="login-form__input"
                type="password"
                placeholder="Retype Password"
                value={user.password2}
                onChange={(e) =>
                  setUser({ ...user, password2: e.target.value })
                }
              />
              <input
                className="login-form__submit"
                type="submit"
                value="Signup"
                ref={btnRef}
              />
              {errors && <div className="login-form-error">{errors}</div>}
            </form>
          </Fragment>
        ) : (
          <div>
            <h3 className="popup-box__subtitle popup-box__subtitle--center">
              Register
            </h3>{" "}
            <p className="popup-box__text">
              <b>Thanks for registering on Ilga World Monitor.</b>
              <br />
              Please follow the steps below.
            </p>
            <div className="popup-box__steps">
              <div className="popup-box__step">
                <img src="/assets/img/register-step-1.png" />
                <p>
                  Check your
                  <br /> e-mail
                </p>
              </div>
              <div className="popup-box__step">
                <img src="/assets/img/register-step-2.png" />
                <p>
                  Click link for
                  <br />
                  confirmation
                </p>
              </div>
              <div className="popup-box__step">
                <img src="/assets/img/register-step-3.png" />
                <p>
                  You are good
                  <br />
                  to go!
                </p>
              </div>
            </div>
            <button className="login-form__submit" onClick={closeModal}>
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  userStore: state.users.current
});

const mapDispatchToProps = (dispatch) => ({
  setUserStore: (user) => dispatch(setUser(user)),
  setLoggedIn: (should) => dispatch(setLoggedIn(should))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
