import { ths } from "../Lib/WMHelper";
import { utc } from "moment-timezone";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import Entry from "../Components/Entry";
import FlagImg from "../Components/FlagImg";
import "../Components/react-dates-overrides.css";
import ReportPopup from "../Components/ReportPopup";
import SaveFolderPopup from "../Components/SaveFolderPopup";
import SearchBar from "../Components/SearchBar";
import { searchGroupBy, searchSortBy } from "../config/constants";
import MainLayout from "../Layout/MainLayout";
import {
  getJurisdictions,
  getLanguages,
  getReportReasons,
  getSourceNames,
  getSourceTypes,
  getTags
} from "../redux/actions/related";
import {
  addSavedFolder,
  executeSearch,
  getSavedFolders,
  readSavedSearch,
  setCurrentSearch,
  toggleDaughters,
  updateSavedFolder
} from "../redux/actions/search";
import "./SearchResults.css";

const SearchResults = ({
  loading,
  results,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  loading_tags,
  sourceTypes,
  getSourceTypes,
  toggleDaughters,
  loading_daughters,
  currentSearch,
  setCurrentSearch,
  executeSearch,
  readSavedSearch,
  filterPendingExecution,
  report_reasons,
  getReportReasons,
  user,
  savedFolders,
  getSavedFolders,
  addSavedFolder,
  updateSavedFolder
}) => {
  useEffect(() => {
    if ((!tags || !tags.length) && !loading_tags) getTags();
    if (!report_reasons || !report_reasons.length) getReportReasons();
    if (!savedFolders || !savedFolders.length) getSavedFolders();
  }, []);
  const cal = useRef(null);
  const [showMax, setShowMax] = useState({});
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [originalCalendar, setOriginalCalendar] = useState({});
  const [customRange, setCustomRange] = useState(false);
  const [articlesSelected, setArticlesSelected] = useState([]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [saveFolderPopupOpen, setSaveFolderPopupOpen] = useState(null);
  const [filters, setFilters] = useState({
    startDate: currentSearch.from
      ? utc(currentSearch.from)
      : utc().subtract(1, "months"),
    endDate: currentSearch.to ? utc(currentSearch.to) : utc(),
    sortBy: "dd",
    groupBy: "st"
  });

  const [currentGroups, setCurrentGroups] = useState([]);
  const [reportArticle, setReportArticle] = useState(null);
  const [alert, setAlert] = useState("");

  const selectAll = () => {
    let articlesTmp = [];
    for (let key of Object.keys(results)) {
      console.log(currentGroups[key]);
      articlesTmp = articlesTmp.concat(results[key]);
    }
    console.log(articlesTmp);
    setArticlesSelected(articlesTmp);
  };

  const toggleCalendar = (visible) => {
    return;
    if (!visible && !customRange) {
      if (
        document.getElementsByClassName("DayPicker_wrapper__horizontal_1")[0] &&
        document.getElementsByClassName("DayPicker_wrapper__horizontal_1")[0]
          .style &&
        focusedInput
      ) {
        setOriginalCalendar({
          ...originalCalendar,
          display: "block"
        });
        document.getElementsByClassName(
          "DayPicker_wrapper__horizontal_1"
        )[0].style.display = "none";

        setTimeout(() => {
          setOriginalCalendar({
            ...originalCalendar,
            width: "722px"
          });
          document.getElementsByClassName(
            "DayPicker__horizontal"
          )[0].style.width = "150px";
        }, 1);
      }
    } else {
      if (
        document.getElementsByClassName("DayPicker_wrapper__horizontal_1")[0] &&
        document.getElementsByClassName("DayPicker_wrapper__horizontal_1")[0]
          .style &&
        focusedInput
      ) {
        console.log(originalCalendar);
        document.getElementsByClassName(
          "DayPicker_wrapper__horizontal_1"
        )[0].style.display = "inline-block";
        document.getElementsByClassName(
          "DayPicker__horizontal"
        )[0].style.width = originalCalendar.width;
      }
    }
  };

  const addArticlesSelected = (article) => {
    setArticlesSelected([...articlesSelected, article]);
  };

  const removeArticlesSelected = (article) => {
    setArticlesSelected(articlesSelected.filter((a) => a.id !== article.id));
  };

  useEffect(() => {
    toggleCalendar(false);
  }, [focusedInput]);

  useEffect(() => {
    setCurrentSearch(
      {
        ...currentSearch,
        from: filters.startDate,
        to: filters.endDate ? filters.endDate : filters.startDate,
        sortBy: filters.sortBy,
        groupBy: filters.groupBy
      },
      true
    ).then((res) => {
      if (
        filters.startDate && filters.endDate
          ? filters.endDate
          : filters.startDate
      )
        executeSearch();
    });
  }, [filters.startDate, filters.endDate, filters.sortBy, filters.groupBy]);

  const history = useHistory();
  let { hash_link } = useParams();

  useEffect(() => {
    if (hash_link) {
      console.log(
        JSON.parse(Buffer.from(hash_link, "base64").toString("utf8"))
      );
      setCurrentSearch(
        {
          ...JSON.parse(Buffer.from(hash_link, "base64").toString("utf8")),
          from: filters.startDate,
          to: filters.endDate ? filters.endDate : filters.startDate,
          sortBy: filters.sortBy,
          groupBy: filters.groupBy
        },
        true
      ).then((res) => {
        readSavedSearch(
          JSON.parse(Buffer.from(hash_link, "base64").toString("utf8"))
            .search_id
        );
        if (
          filters.startDate && filters.endDate
            ? filters.endDate
            : filters.startDate
        ) {
          setArticlesSelected([]);
          executeSearch();
        }
      });
    }
  }, []);

  const countryGroupLabel = (country) => {
    if (country)
      return (
        <Fragment>
          {" "}
          <FlagImg
            classes="entry__country-flag"
            code={country.country_code}
            name={country.name}
          />
          <b class="entry__country-name">{country.name}</b>
        </Fragment>
      );
    return "-";
  };

  return (
    <MainLayout
      showControls={true}
      alert={alert}
      setAlert={setAlert}
      articlesSelected={articlesSelected}
      setArticlesSelected={setArticlesSelected}
      jurisdictions={jurisdictions}
      sharePopupOpen={sharePopupOpen}
      setSharePopupOpen={setSharePopupOpen}
      setSaveFolderPopupOpen={setSaveFolderPopupOpen}
      saveFolderPopupOpen={saveFolderPopupOpen}
    >
      {" "}
      <SearchBar tags={tags} setArticlesSelected={setArticlesSelected} />
      <ReportPopup
        reportReasons={report_reasons}
        reportArticle={reportArticle}
        setReportArticle={setReportArticle}
        user={user}
      />
      <section class="entries-container">
        <div class="entries-sorting">
          <div className="main-calendar">
            <button
              class="date-range-btn"
              onClick={(e) => {
                setFocusedInput("endDate");
              }}
            >
              Date range <span style={{ color: "#5ea5d2" }}>&gt;</span>
            </button>

            <DateRangePicker
              numberOfMonths="2"
              readOnly={true}
              ref={cal}
              startDate={filters.startDate} // momentPropTypes.momentObj or null,
              startDateId="startDateId" // PropTypes.string.isRequired,
              endDate={filters.endDate} // momentPropTypes.momentObj or null,
              endDateId="endDateId" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => {
                if (!endDate) endDate = startDate;
                setFilters({ ...filters, startDate, endDate });
                //setFocusedInput(startDate && !endDate ? "endDate" : null);
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(fI) => {
                //if (!focusedInput)
                setFocusedInput(fI);
                //else setFocusedInput(null);
              }} // PropTypes.func.isRequired,
              renderCalendarInfo={() => (
                <div className="calendar-presets">
                  <button
                    onClick={(e) => {
                      setCustomRange(false);
                      setFilters({
                        ...filters,
                        startDate: utc().subtract(1, "week"),
                        endDate: utc()
                      });
                      setFocusedInput(null);
                    }}
                    style={{ backgroundColor: "#F3722C" }}
                  >
                    Past week
                  </button>

                  <button
                    onClick={(e) => {
                      setCustomRange(false);
                      setFilters({
                        ...filters,
                        startDate: utc().subtract(1, "month"),
                        endDate: utc()
                      });
                      setFocusedInput(null);
                    }}
                    style={{ backgroundColor: "#F8961E" }}
                  >
                    Past month
                  </button>

                  <button
                    onClick={(e) => {
                      setCustomRange(false);
                      setFilters({
                        ...filters,
                        startDate: utc().subtract(3, "months"),
                        endDate: utc()
                      });
                      setFocusedInput(null);
                    }}
                    style={{ backgroundColor: "#90BE6D" }}
                  >
                    Past 3 months
                  </button>

                  <button
                    onClick={(e) => {
                      setCustomRange(false);
                      setFilters({
                        ...filters,
                        startDate: utc().subtract(6, "months"),
                        endDate: utc()
                      });
                      setFocusedInput(null);
                    }}
                    style={{ backgroundColor: "#43AA8B" }}
                  >
                    Past 6 months
                  </button>

                  <button
                    onClick={(e) => {
                      setCustomRange(false);
                      setFilters({
                        ...filters,
                        startDate: utc().subtract(12, "months"),
                        endDate: utc()
                      });
                      setFocusedInput(null);
                    }}
                    style={{ backgroundColor: "#4D908E" }}
                  >
                    Past Year
                  </button>

                  <button
                    onClick={(e) => {
                      setCustomRange(true);
                      toggleCalendar(true);
                    }}
                    style={{ backgroundColor: "#577590" }}
                  >
                    Custom range &gt;
                  </button>
                </div>
              )}
              calendarInfoPosition="before"
              hideKeyboardShortcutsPanel={true}
              minDate={utc("2020-01-01")}
              maxDate={utc()}
              minimumNights={0}
              isOutsideRange={(day) => {
                if (utc().diff(day) < 1) {
                  return true;
                }
                return utc(day) <= utc("2020-05-19");
              }}
              small
              displayFormat={() => "DD MMM YYYY"}
              isDayBlocked={() => !customRange}
            />
          </div>
          <div class="entries-sorting__dropdown">
            <p>
              Group by: <strong>{searchGroupBy[currentSearch.groupBy]}</strong>
            </p>
            <ul class="entries-sorting-options">
              {Object.keys(searchGroupBy).map((k, i) => (
                <li
                  key={i}
                  class="entries-sorting-options__option"
                  onClick={(e) =>
                    setFilters(
                      {
                        ...filters,
                        groupBy: k
                      },
                      true
                    )
                  }
                >
                  {searchGroupBy[k]}
                </li>
              ))}
            </ul>
          </div>

          <div class="entries-sorting__dropdown">
            <p>
              Sort by: <strong>{searchSortBy[currentSearch.sortBy]}</strong>
            </p>
            <ul class="entries-sorting-options">
              {Object.keys(searchSortBy).map(
                (k, i) =>
                  (currentSearch.query || k !== "re") &&
                  (!(
                    (currentSearch.sources && currentSearch.sources.length) ||
                    (currentSearch.source_types && currentSearch.source_types.length) ||
                    (currentSearch.source_locations && currentSearch.source_locations.length) ||
                    (currentSearch.languages && currentSearch.languages.length)
                  ) ||
                    k !== "pa") &&
                  (!(
                    (currentSearch.sources && currentSearch.sources.length) ||
                    (currentSearch.source_types && currentSearch.source_types.length) ||
                    (currentSearch.source_locations && currentSearch.source_locations.length) ||
                    (currentSearch.languages && currentSearch.languages.length)
                  ) ||
                    k !== "pd") && (
                    <li
                      key={i}
                      class="entries-sorting-options__option"
                      onClick={(e) =>
                        setFilters(
                          {
                            ...filters,
                            sortBy: k
                          },
                          true
                        )
                      }
                    >
                      {searchSortBy[k]}{" "}
                      {k === "da" || k === "pa" || k === "ja" ? (
                        <img src="/assets/svgs/sort-asc.svg" height="18px" style={{ float: "right" }} />
                      ) : (
                        <img src="/assets/svgs/sort-desc.svg" height="18px" style={{ float: "right" }} />
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
        {loading || !results ? (
          <div class="loading-gif">
            <img src="/assets/img/loading.gif" />
          </div>
        ) : results && Object.keys(results).length ? (
          Object.keys(results).map((gb, key) => (
            <Fragment key={key}>
              <div class="entries-accordion">
                <label
                  id={"label" + key}
                  onClick={(e) => {
                    currentGroups.indexOf(gb) !== -1
                      ? setCurrentGroups(currentGroups.filter((cg) => cg !== gb))
                      : setCurrentGroups([...currentGroups, gb]);
                  }}
                  class="entries-accordion__title"
                  for="media-outlets"
                >
                  {currentSearch.groupBy !== "c" && currentSearch.groupBy !== "j"
                    ? gb
                    : countryGroupLabel(jurisdictions.find((j) => j.country_code === gb))}{" "}
                  ({ths(results[gb].length)})
                </label>
              </div>

              <label
                class="entries-accordion__toggle"
                onClick={(e) => {
                  currentGroups.indexOf(gb) !== -1
                    ? setCurrentGroups(currentGroups.filter((cg) => cg !== gb))
                    : setCurrentGroups([...currentGroups, gb]);
                }}
                for="media-outlets"
              >
                {currentGroups.indexOf(gb) !== -1 ? "-" : "+"}
              </label>

              <div
                class="entries-accordion-content"
                style={{
                  ...(currentGroups.indexOf(gb) !== -1 ? { display: "block" } : { display: "none" })
                }}
              >
                {currentGroups.indexOf(gb) !== -1 &&
                  results[gb]
                    .slice(0, showMax[gb] ? showMax[gb] : 50)
                    .map((article, index) => (
                      <Entry
                        key={index}
                        article={article}
                        languages={languages}
                        jurisdictions={jurisdictions}
                        sourceNames={sourceNames}
                        sourceTypes={sourceTypes}
                        tags={tags}
                        toggleDaughters={toggleDaughters}
                        loading_daughters={loading_daughters}
                        group={gb}
                        setAlert={setAlert}
                        addArticlesSelected={addArticlesSelected}
                        removeArticlesSelected={removeArticlesSelected}
                        setArticlesSelected={setArticlesSelected}
                        setSharePopupOpen={setSharePopupOpen}
                        reportArticle={reportArticle}
                        setReportArticle={setReportArticle}
                        saveFolderPopupOpen={saveFolderPopupOpen}
                        setSaveFolderPopupOpen={setSaveFolderPopupOpen}
                        articlesSelected={articlesSelected}
                        selectAll={selectAll}
                      />
                    ))}
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible)
                      setShowMax({
                        ...showMax,
                        [gb]: (showMax[gb] ? showMax[gb] : 50) + 30
                      });
                  }}
                >
                  <div>&nbsp;</div>
                </VisibilitySensor>
              </div>
            </Fragment>
          ))
        ) : (
          <div className="no-results">
            <p>
              <strong>Oops! It appears that we have no results for your query.</strong>
            </p>
            <p>
              Have you tried expanding the <strong>time range</strong> for your query? If you are combining several
              parameters try removing some of them to improve your results.
            </p>
            <p>
              For further information, see our <Link to="/help-faq">Help & FAQ section</Link>.
            </p>
          </div>
        )}
      </section>
      <SaveFolderPopup
        isOpen={saveFolderPopupOpen}
        setIsOpen={setSaveFolderPopupOpen}
        savedFolders={savedFolders}
        addSavedFolder={addSavedFolder}
        updateSavedFolder={updateSavedFolder}
        entries={articlesSelected}
      />
    </MainLayout>
  );
};
const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  loading: state.search.loading,
  results: state.search.results,
  loading_daughters: state.search.loading_daughters,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  filterPendingExecution: state.search.filterPendingExecution,
  loading_tags: state.related.loading_tags,
  report_reasons: state.related.report_reasons,
  user: state.users.current,
  savedFolders: state.search.savedFolders
});

const mapDispatchToProps = (dispatch) => ({
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  toggleDaughters: (article, group) =>
    dispatch(toggleDaughters(article, group)),
  setCurrentSearch: (search, filter) =>
    dispatch(setCurrentSearch(search, filter)),
  executeSearch: (search) => dispatch(executeSearch(search)),
  readSavedSearch: (id) => dispatch(readSavedSearch(id)),
  getReportReasons: () => dispatch(getReportReasons()),
  getSavedFolders: () => dispatch(getSavedFolders()),
  addSavedFolder: (entries, name) => dispatch(addSavedFolder(entries, name)),
  updateSavedFolder: (entries, id) => dispatch(updateSavedFolder(entries, id))
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
