import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment
} from "react";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import { AutoScroller } from "../Lib/AutoScroller";
import { utc } from "moment-timezone";

import {
  getLatestMouGeneral,
  getLatestOtherGeneral
} from "../redux/actions/articles";
import { connect } from "react-redux";
import FlagImg from "./FlagImg";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const EntriesList = ({
  special,
  isMou,
  jurisdiction,
  tag,
  language,
  //End components props
  getLatestMouGeneral,
  latest_mou_general,
  getLatestOtherGeneral,
  latest_other_general,
  loadedArticles
}) => {
  const [listArticles, setListArticles] = useState([]);

  useEffect(() => {
    if (
      special === "general" &&
      isMou &&
      (!latest_mou_general || !latest_mou_general.length)
    )
      getLatestMouGeneral();
    if (
      special === "general" &&
      !isMou &&
      (!latest_other_general || !latest_other_general.length)
    )
      getLatestOtherGeneral();
  }, []);

  useEffect(() => {
    if (special === "general") {
      if (!isMou) {
        setListArticles(latest_other_general);
      }
    }
  }, [latest_other_general]);

  useEffect(() => {
    if (special === "general") {
      if (isMou) {
        setListArticles(latest_mou_general);
      }
    }
  }, [latest_mou_general]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [listArticles]);

  useEffect(() => {
    if (loadedArticles) {
      setListArticles(loadedArticles);
    }
  }, [loadedArticles]);
  return (
    <Fragment>
      {listArticles.length > 5 ? (
        <AutoScroller
          speed={3000}
          initial={6}
          classes={"entries-list"}
          pauseOver={true}
          subclasses={"entries-list-entry2"}
        >
          {listArticles.map((art, key) => (
            <a
              className="entries-list__item"
              href={art.u}
              target="_blank"
              rel="noopener noreferrer nofollow"
              key={key}
            >
              {special === "jur" ? (
                <FlagImg
                  code={jurisdiction.country_code}
                  name={jurisdiction.country_code}
                />
              ) : !art.j ? (
                <FlagImg code={art.c} name={art.cn} />
              ) : (
                <FlagImg
                  code={art.j.split(",")[0]}
                  name={art.j.split(",")[0]}
                />
              )}

              <p
                data-tip={
                  utc(art.da).format("D MMM YYYY") +
                  " • " +
                  art.cn +
                  " • " +
                  art.s
                }
              >
                {art.th
                  ? art.th.slice(0, 120) + (art.th.length > 120 ? "..." : "")
                  : art.h.slice(0, 120) + (art.h.length > 120 ? "..." : "")}
              </p>
              <b>{art.st}</b>
              <button></button>
            </a>
          ))}
        </AutoScroller>
      ) : listArticles.length > 0 ? (
        <Fragment>
          {listArticles.map((art, key) => (
            <a
              className="entries-list__item"
              href={art.u}
              target="_blank"
              rel="noopener noreferrer nofollow"
              key={key}
            >
              {special === "jur" ? (
                <FlagImg
                  code={jurisdiction.country_code}
                  name={jurisdiction.country_code}
                />
              ) : !art.j ? (
                <FlagImg code={art.c} name={art.cn} />
              ) : (
                <FlagImg
                  code={art.j.split(",")[0]}
                  name={art.j.split(",")[0]}
                />
              )}

              <p
                data-tip={
                  utc(art.da).format("D MMM YYYY") +
                  " • " +
                  art.cn +
                  " • " +
                  art.s
                }
              >
                {art.th
                  ? art.th.slice(0, 120) + (art.th.length > 120 ? "..." : "")
                  : art.h.slice(0, 120) + (art.h.length > 120 ? "..." : "")}
              </p>
              <b>{art.st}</b>
              <button></button>
            </a>
          ))}
        </Fragment>
      ) : (
        listArticles !== -1 && (
          <div className="LoadingBar">
            <div className="LoadingBar-progress"></div>
          </div>
        )
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  latest_mou_general: state.articles.latest_mou_general,
  latest_other_general: state.articles.latest_other_general
});

const mapDispatchToProps = (dispatch) => ({
  getLatestMouGeneral: () => dispatch(getLatestMouGeneral()),
  getLatestOtherGeneral: () => dispatch(getLatestOtherGeneral())
});
export default connect(mapStateToProps, mapDispatchToProps)(EntriesList);
