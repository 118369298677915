import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment
} from "react";
import Select, { components, createFilter } from "react-select";

import { FixedSizeList } from "react-window";

const SearchBar = ({
  options,
  placeholder,
  color,
  type,
  setCurrentSearch,
  currentSearch,
  plural,
  preprocess
}) => {
  const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = "";

    if (values.length > 0)
      valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1)
      valueLabel = values.length + ` ` + plural + `  selected`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter(
      (child) =>
        ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );

    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue && valueLabel}
        {childsToRender}
      </components.ValueContainer>
    );
  };

  const optimizeSelect = {
    components: {
      MenuList: OptimizedMenuList,
      Option: OptimizedOption,
      ValueContainer: ValueContainer
    }
  };

  function OptimizedMenuList(props) {
    const { options, children, maxHeight, getValue } = props;
    if (!children || !Array.isArray(children)) return null;

    const height = 25;
    const selectedValues = getValue();
    const initialOffset = selectedValues[0]
      ? options.indexOf(selectedValues[0]) * height
      : 0;

    return (
      <FixedSizeList
        width={""}
        itemSize={height}
        height={maxHeight}
        itemCount={children.length}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <div className="option-wrapper" style={style}>
            {children[index]}
          </div>
        )}
      </FixedSizeList>
    );
  }

  function OptimizedOption(props) {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
    return (
      <components.Option
        {...props}
        style={{ ...props.style, whiteSpace: "nowrap" }}
        className={props.className + " notranslate nowrap"}
      >
        {props.children}
      </components.Option>
    );
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  }

  if (!preprocess) preprocess = (value) => value;

  return (
    <Select
      autosize={true}
      options={
        options && options.length > 0
          ? options
          : [{ id: "", name: "Loading...", isDisabled: true }]
      }
      placeholder={placeholder}
      onChange={(value) => {
        console.log(value);
        setCurrentSearch({ ...currentSearch, [type]: preprocess(value) });
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={currentSearch[type] || null}
      isMulti={true}
      components={
        options.length > 500 ? optimizeSelect.components : { ValueContainer }
      }
      hideSelectedOptions={true}
      filterOption={createFilter({ ignoreAccents: false })}
      styles={{
        control: (base, state) => ({
          ...base,
          background: color,
          height: 35,
          color: "#FFF",
          fontWeight: "bold",
          // match with the menu
          borderRadius: state.isFocused ? "5px" : "5px",
          // Overwrittes the different states of border
          borderColor: state.isFocused ? color : color,
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? color : color
          }
        }),
        menu: (base) => ({
          ...base,
          // override border radius to match the box
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
          color: "#fff",
          backgroundColor: color,
          zIndex: 9999
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled ? color : color,
            color: "#FFF",
            fontWeight: "bold",
            cursor: isDisabled ? "not-allowed" : "default",

            padding: "5px 8px 5px 8px",
            "&:hover": {
              // Overwrittes the different states of border
              backgroundColor:
                "rgba(" +
                hexToRgb(color).r +
                10 +
                "," +
                hexToRgb(color).g +
                10 +
                "," +
                hexToRgb(color).b +
                10 +
                ",0.1)"
            }
          };
        },
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
          color: "#fff",
          backgroundColor: color
        }),
        singleValue: (base) => ({
          ...base,
          color: "#fff",
          backgroundColor: color
        }),
        input: (base) => ({
          ...base,
          color: "#fff",
          backgroundColor: color
        }),
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            fontSize: "1.4rem",
            color: "#ffffff"
          };
        },
        valueContainer: (defaultStyles) => ({
          // all your override styles
          ...defaultStyles,
          maxHeight: "35px"
        }),

        indicatorsContainer: (defaultStyles) => ({
          // all your override styles
          ...defaultStyles,
          maxHeight: "35px"
        })
      }}
    />
  );
};

export default SearchBar;
