import React, { useState, useContext, useRef, useEffect } from "react";
import { confirmWrapper, confirm } from "../Components/Confirm";

import MainLayout from "../Layout/MainLayout";

import { utc } from "moment-timezone";
import { connect } from "react-redux";
import {
  getJurisdictions,
  getLanguages,
  getSourceNames,
  getSourceTypes,
  getTags
} from "../redux/actions/related";
import {
  resetCurrentSearch,
  setCurrentSearch,
  getSavedSearches
} from "../redux/actions/search";
import { useHistory } from "react-router-dom";

const AllTopics = ({
  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  getSavedSearches,
  savedSearches
}) => {
  const history = useHistory();

  const [currentGroups, setCurrentGroups] = useState([]);

  const handleDelete = async (searchC) => {
    if (
      await confirm({
        confirmation: "Are you sure?",
        options: { title: "Deleting " + searchC.name + " Search Criteria" }
      })
    ) {
      console.log("yes");
    } else {
      console.log("no");
    }
  };

  useEffect(() => {
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!savedSearches || !savedSearches.length) getSavedSearches();
  }, []);
  return (
    <MainLayout showControls={true}>
      <section className="saved-search-criteria">
        <h1 className="saved-search-criteria__title">
          My Saved Search Criteria
        </h1>
        {savedSearches.length > 0
          ? savedSearches.map((search, key) => (
              <div className="search-criteria-container">
                <div className="search-criteria-accordion">
                  <label
                    id={"label" + key}
                    onClick={(e) => {
                      currentGroups.indexOf(search.id) !== -1
                        ? setCurrentGroups(
                            currentGroups.filter((cg) => cg !== search.id)
                          )
                        : setCurrentGroups([...currentGroups, search.id]);
                    }}
                    className="search-criteria-accordion__title"
                    for="criteria1"
                  >
                    {search.name}
                  </label>
                  <span className="saved-search-date">
                    Created: {utc(search.updated_at).format("DD MMM YYYY")}
                  </span>
                </div>

                <div className="search-criteria-accordion__notifications">
                  <button
                    className={
                      "search-criteria-accordion__notification" +
                      (search.new_entries
                        ? " search-criteria-accordion__notification--active"
                        : "")
                    }
                  ></button>
                </div>
                <label
                  onClick={(e) => {
                    currentGroups.indexOf(search.id) !== -1
                      ? setCurrentGroups(
                          currentGroups.filter((cg) => cg !== search.id)
                        )
                      : setCurrentGroups([...currentGroups, search.id]);
                  }}
                  className="search-criteria-accordion__toggle"
                  for="criteria1"
                >
                  {currentGroups.indexOf(search.id) !== -1 ? "-" : "+"}
                </label>

                <div
                  className="search-criteria-accordion-content"
                  style={{
                    ...(currentGroups.indexOf(search.id) !== -1
                      ? { display: "flex" }
                      : { display: "none" })
                  }}
                >
                  <div className="search-criteria-accordion__actions">
                    <button
                      className="search-criteria-accordion__action search-criteria-accordion__action--delete"
                      onClick={(e) => handleDelete(search)}
                    ></button>
                    <button
                      className="search-criteria-accordion__action search-criteria-accordion__action--go-to"
                      onClick={(e) => {
                        console.log(search.search);
                        setCurrentSearch({
                          ...search.search,
                          from: utc().subtract(1, "month"),
                          to: utc()
                        });
                        history.push("/search-results");
                      }}
                    ></button>
                  </div>
                  {search.search.jurisdictions &&
                    search.search.jurisdictions.length > 0 && (
                      <div className="criteria">
                        <h4 className="criteria__title">Jurisdictions</h4>
                        <div className="criteria__tags">
                          {search.search.jurisdictions.map((jur) => (
                            <span className="criteria__tag criteria__tag--jurisdiction">
                              {jur.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  {search.search.tags && search.search.tags.length > 0 && (
                    <div className="criteria">
                      <h4 className="criteria__title">Topics</h4>
                      <div className="criteria__tags">
                        {search.search.tags.map((tags) => (
                          <span className="criteria__tag criteria__tag--topic">
                            {tags.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {search.search.languages &&
                    search.search.languages.length > 0 && (
                      <div className="criteria">
                        <h4 className="criteria__title">Languages</h4>
                        <div className="criteria__tags">
                          {search.search.languages.map((lang) => (
                            <span className="criteria__tag criteria__tag--language">
                              {lang.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                  {search.search.source_types &&
                    search.search.source_types.length > 0 && (
                      <div className="criteria">
                        <h4 className="criteria__title">Source Types</h4>
                        <div className="criteria__tags">
                          {search.search.source_types.map((st) => (
                            <span className="criteria__tag criteria__tag--source-type">
                              {st.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                  {search.search.sources && search.search.sources.length > 0 && (
                    <div className="criteria">
                      <h4 className="criteria__title">Sources</h4>
                      <div className="criteria__tags">
                        {search.search.sources.map((s) => (
                          <span className="criteria__tag criteria__tag--source">
                            {s.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {search.search.source_locations &&
                    search.search.source_locations.length > 0 && (
                      <div className="criteria">
                        <h4 className="criteria__title">Source Locations</h4>
                        <div className="criteria__tags">
                          {search.search.source_locations.map((sl) => (
                            <span className="criteria__tag criteria__tag--source-location">
                              {sl.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                  {search.search.query && search.search.query.length > 0 && (
                    <div className="criteria">
                      <h4 className="criteria__title">Query</h4>
                      <div className="criteria__tags">
                        <span className="">{search.search.query}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          : ""}
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  savedSearches: state.search.savedSearches
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getSavedSearches: () => dispatch(getSavedSearches())
});
export default connect(mapStateToProps, mapDispatchToProps)(AllTopics);
