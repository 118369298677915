import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import CountriesStats from "../Components/CountriesStats";
import EntriesList from "../Components/EntriesList";
import SearchBar from "../Components/SearchBar";
import SourceTypesStats from "../Components/SourceTypesStats";
import SvgMap from "../Components/SvgMap";
import TagStats from "../Components/TagStats";
import { AuthContext } from "../index";
import MainLayout from "../Layout/MainLayout";
import { getLatest } from "../redux/actions/articles";
import {
  getJurisdictions,
  getLanguages,
  getSourceNames,
  getSourceTypes,
  getTags,
  getLanguageStats
} from "../redux/actions/related";
import { resetCurrentSearch, setCurrentSearch } from "../redux/actions/search";

const ProfileLanguage = ({
  match,

  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  tags,
  getTags,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  latest,
  getLatest,
  getLanguageStats,
  loading_language_stats
}) => {
  let { slug } = useParams();
  const history = useHistory();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [language, setLanguage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!tags || !tags.length) getJurisdictions();
    if (!jurisdictions || !jurisdictions.length) getTags();
    if (!languages || !languages.length) getLanguages();

    if (language && language.id && !loaded) {
      setLoaded(true);

      if (!latest || !latest.mou.tag[language.id])
        getLatest(1, "language", language.id);
      if (!latest || !latest.nomou.tag[language.id])
        getLatest(0, "language", language.id);
    }
  }, []);

  useEffect(() => {
    if (language && language.id && !loaded) {
      setLoaded(true);
      if (!latest || !latest.mou.language[language.id])
        getLatest(1, "language", language.id);
      if (!latest || !latest.nomou.tag[language.id])
        getLatest(0, "language", language.id);
      if (language && !language.stats && !loading_language_stats) {
        getLanguageStats(language.id).then(console.log(language));
      }
    }
  }, [language]);

  useEffect(() => {
    if (languages.length) {
      let languageTmp = languages.find((t) => t.slug === slug);
      if (languageTmp) {
        setLanguage(languageTmp);
        setCurrentSearch({ ...currentSearch, languages: [languageTmp] });
      } else history.push("/home");
    }
  }, [languages]);

  const Auth = useContext(AuthContext);
  return (
    <MainLayout showControls={true}>
      {" "}
      {tags && <SearchBar tags={tags} />}
      {languages.length && (
        <Fragment>
          <section className="topic-title">
            <h1>{language.name}</h1>
            <p>Language profile</p>
            <hr></hr>
          </section>
          <section className="cards-container">
            <div className="card card--50">
              <h3 className="card__title">Media Outlets</h3>
              <div className="card__content">
                <EntriesList
                  special="language"
                  isMou={true}
                  language={language}
                  loadedArticles={language && latest.mou.language[language.id]}
                />
              </div>
              {latest.mou.language[language.id] !== -1 && (
                <Link
                  to="/search-results"
                  className="card__more"
                  onClick={(e) =>
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: [
                        sourceTypes.find((st) => st.code === "MOU")
                      ]
                    })
                  }
                >
                  Search more entries from media outlets &gt;
                </Link>
              )}
            </div>
            <div className="card card--50">
              <h3 className="card__title">Other Sources</h3>
              <div className="card__content">
                <EntriesList
                  isMou={false}
                  special="language"
                  language={language}
                  loadedArticles={
                    language && latest.nomou.language[language.id]
                  }
                />
              </div>
              {latest.nomou.language[language.id] !== -1 && (
                <Link
                  to="/search-results"
                  className="card__more"
                  onClick={(e) => {
                    console.log(sourceTypes.slice(1));
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: sourceTypes.filter(
                        (st) => st.code !== "MOU"
                      )
                    });
                  }}
                >
                  Search more entries &gt;
                </Link>
              )}
            </div>
            <div className="card card--55">
              <h3 className="card__title">
                Entries for {language.name} by jurisdiction
              </h3>
              <div className="card__content">
                {jurisdictions &&
                  jurisdictions.length > 0 &&
                  language.stats &&
                  language.stats.jurisdictions && (
                    <SvgMap
                      gotoSearch={true}
                      setCurrentSearch={setCurrentSearch}
                      currentSearch={currentSearch}
                      countries={jurisdictions.map((j) => ({
                        ...j,
                        n_approved: language.stats.jurisdictions.find(
                          (jj) => jj.jurisdiction_id === j.id
                        )
                          ? language.stats.jurisdictions.find(
                              (jj) => jj.jurisdiction_id === j.id
                            ).n_approved
                          : 0
                      }))}
                      setMainMapLoaded={setMapLoaded}
                      coef_ini={Math.max.apply(
                        Math,
                        language.stats.jurisdictions.map(function (o) {
                          return o.n_approved;
                        })
                      )}
                    />
                  )}
              </div>
            </div>
            <div className="card card--45">
              <h3 className="card__title">
                Total entries for {language.name} by jurisdiction
              </h3>
              <div className="card__content">
                {jurisdictions &&
                  jurisdictions.length > 0 &&
                  language.stats &&
                  language.stats.jurisdictions && (
                    <CountriesStats
                      countries={jurisdictions.map((j) => ({
                        ...j,
                        n_approved: language.stats.jurisdictions.find(
                          (jj) => jj.jurisdiction_id === j.id
                        )
                          ? language.stats.jurisdictions.find(
                              (jj) => jj.jurisdiction_id === j.id
                            ).n_approved
                          : 0
                      }))}
                      gotoSearch={true}
                      setCurrentSearch={setCurrentSearch}
                      currentSearch={currentSearch}
                    />
                  )}
              </div>
            </div>

            <div className="card card--40 card--55md">
              <span className="card__subtitle">Most Reported</span>
              <h3 className="card__title card__title--bordered">
                Topics for {language.name}
              </h3>
              {language.stats &&
                language.stats.tags &&
                language.stats.tags.length && (
                  <div className="card__content">
                    <TagStats
                      slice={40}
                      alwaysReload={true}
                      gotoSearch={true}
                      setCurrentSearch={setCurrentSearch}
                      currentSearch={currentSearch}
                      tags={language.stats.tags.map((t) => ({
                        ...tags.find((t2) => t2.id === t.tag_id),
                        n_approved: t.n_approved
                      }))}
                    />
                  </div>
                )}
              <Link to="/all-topics" className="card__more--small">
                Search other topics >
              </Link>
            </div>
            <div className="card card--60 card--45md">
              <span className="card__subtitle">Non Media-Outlet Sources</span>

              <h3 className="card__title card__title--bordered">
                Entries for {language.name} by source type
              </h3>
              {sourceTypes &&
              sourceTypes.length > 0 &&
              language.stats &&
              language.stats.source_types ? (
                <div className="card__content">
                  <SourceTypesStats
                    data={language.stats.source_types.map((st) => ({
                      ...sourceTypes.find(
                        (st2) => st2.id === st.source_type_id
                      ),
                      n_approved: st.n_approved
                    }))}
                    sourceTypes={sourceTypes}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </section>
        </Fragment>
      )}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  latest: state.articles.latest,
  loading_language_stats: state.related.loading_language_stats
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getLatest: (mou, type, id) => dispatch(getLatest(mou, type, id)),
  getLanguageStats: (tag_id) => dispatch(getLanguageStats(tag_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileLanguage);
