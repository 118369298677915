import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import { utc } from "moment-timezone";
import { Fragment } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

const ReportPopup = ({
  reportReasons,
  reportArticle,
  setReportArticle,
  user
}) => {
  const [report, setReport] = useState({ reason: null, other: "" });

  const [errors, setErrors] = useState({});
  const [saved, setSaved] = useState(false);

  const [error, setError] = useState("");

  const [message, setMessage] = useState("");

  let btnRef = useRef();

  function closeModal() {
    setSaved(false);
    setMessage("");
    setError("");
    setReportArticle(null);
  }

  const { reason, other } = report;

  const handleForm = async (e) => {
    e.preventDefault();
    let shouldSend = true;
    if (!reason || (reason === 7 && !other)) {
      setError("The reason is required");
      setMessage("");
      shouldSend = false;
    }
    if (shouldSend) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.innerHTML = "Sending...";
      }
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/articles/reportArticle",
          {
            article: reportArticle,
            reason,
            other
          }
        );
        btnRef.current.removeAttribute("disabled");
        setError("");
        btnRef.current.innerHTML = "REPORT";
        setMessage(
          "Thank you for taking the time to report an error on our database. We will process it shortly."
        );
        setReport({});
      } catch (e) {
        btnRef.current.innerHTML = "There was an error...";
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let res;
      if (reportArticle) {
        if (user.adminurl) {
          window.open(
            user.adminurl +
              "/dashboard/" +
              (reportArticle.p ? reportArticle.p : reportArticle.id),
            "_blank",
            "noopener,noreferrer"
          );
        }
      }
    })();
  }, [reportArticle]);

  return (
    <div
      className="popup-wrapper"
      style={{
        ...(reportArticle ? { display: "flex" } : { display: "none" })
      }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div
        className={
          "popup-box" + (message ? " popup-box-med" : " popup-box-med")
        }
      >
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>

        {!message && (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/announcement.svg" height="48px" />
            </p>
            <h3 className="popup-box__subtitle">
              Report an error for this entry
            </h3>
          </Fragment>
        )}
        {!message ? (
          <form className="report-form">
            <br />
            <p
              style={{
                marginTop: "1.6rem",
                fontSize: "1.4rem",
                textAlign: "center"
              }}
            >
              Please remember that ILGA World cannot check or verify the content
              of each entry. Review our{" "}
              <Link to="/terms-and-conditions" target="_blank">
                disclaimers
              </Link>
              .
            </p>
            {error && <label className="message-error">{error}</label>}
            <div style={{ marginTop: "2.4rem", paddingBottom: "8px" }}>
              Please specify the type of error
            </div>

            <Select
              autosize={true}
              options={
                reportReasons && reportReasons.length > 0
                  ? reportReasons
                  : [{ id: "", name: "Loading...", isDisabled: true }]
              }
              placeholder={"Select an option"}
              onChange={(value) => setReport({ ...report, reason: value })}
              getOptionLabel={(option) => option.reason}
              getOptionValue={(option) => option.id}
              value={reason || null}
              width="100%"
              className="saved-search-select"
            />

            <textarea
              type="text"
              style={{
                ...(reason && reason.id === 6
                  ? { display: "block" }
                  : { display: "none" })
              }}
              className="save-search-criteria-form__input"
              value={other}
              onChange={(e) => setReport({ ...report, other: e.target.value })}
              name="body"
              rows="5"
              placeholder="Type another reason"
            />

            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={handleForm}
            >
              Report
            </button>
            {errors.message && (
              <div className="login-form-error">{errors.message} </div>
            )}
          </form>
        ) : (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/check_circle.svg" height="48px" />
            </p>

            <p className="popup-box__subtitle">{message}</p>
            <br />
            <br />

            <button className="login-form__submit" onClick={closeModal}>
              Close
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ReportPopup;
