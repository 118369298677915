import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment
} from "react";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import "./SvgMap.css";
import WorldMap from "../world.svg";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import FlagImg from "./FlagImg";
import { ths } from "../Lib/WMHelper";

function pickHex(color1, color2, weight) {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
  return rgb;
}

const SvgMap = ({
  countries,
  setMainMapLoaded,
  coef_ini = 3924,
  gotoSearch,
  currentSearch,
  setCurrentSearch
}) => {
  const history = useHistory();
  const [currentCountry, setCurrentCountry] = useState(null);
  const [svgMap, setSvgMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(null);
  const [preventClick, setPreventClick] = useState(false);
  const [startPan, setStartPan] = useState({ x: 0, y: 0 });

  const svgPath = `${WorldMap}#svgView(preserveAspectRatio(none))`;
  let max = Math.max.apply(
    Math,
    countries.map(function (o) {
      return o.n_approved;
    })
  );
  //max = 4000;
  let coef = max / coef_ini;

  coef = Math.round(coef * 2) / 2;
  let steps = [0, 10, 50, 100, 200, 350, 500, 1000];
  if (coef_ini < 1000) {
    steps = [0, 5, 25, 50, 100, 175, 250, 500];
  }
  const getColor = (n) => {
    if (n === steps[0]) return "#fff";
    if (n <= Math.floor(steps[1] * coef)) return "#ebedf1";
    if (n <= Math.floor(steps[2] * coef)) return "#d0d5de";
    if (n <= Math.floor(steps[3] * coef)) return "#b3bbca";
    if (n <= Math.floor(steps[4] * coef)) return "#96a2b6";
    if (n <= Math.floor(steps[5] * coef)) return "#7b89a3";
    if (n <= Math.floor(steps[6] * coef)) return "#5f708f";
    if (n <= Math.floor(steps[7] * coef)) return "#57698a";
    return "#4e6184";
  };
  let pC = useRef(false);
  useEffect(() => {
    countries.forEach((c) => {
      d3.select("#" + c.country_code.toLowerCase())
        .on("mouseenter", (event, d) => {
          setCurrentCountry(c);
          d3.select("#map-tooltip").style("left", event.pageX + 25 + "px");
          d3.select("#map-tooltip").style("top", event.pageY - 25 + "px");
          d3.select("#map-tooltip").style("display", "block");
          d3.select("#" + event.target.id).style("fill", "#203864");
        })
        .on("mouseleave", (event, d) => {
          setCurrentCountry(null);

          d3.select("#map-tooltip").style("display", "none");
          d3.select("#" + event.target.id).style(
            "fill",
            getColor(c.n_approved)
          );
        })
        .style("fill", getColor(c.n_approved))
        //.style("fill-opacity", Math.random() + 0.2)
        .style("cursor", "pointer")
        .style("border", "1px solid black")
        .on("click", (e) => {
          if (!pC.current && c.n_approved)
            gotoSearch
              ? setCurrentSearch({
                  ...currentSearch,
                  jurisdictions: [c]
                }).then(() => history.push("/search-results"))
              : history.push("/jurisdictions/" + c.slug);
        });
    });
  }, [countries, svgMap]);

  const panzoom = useRef(null);

  const beforePan = () => {
    console.log(panzoom.current.getZoom());
    if (Math.round(panzoom.current.getZoom()) === 1) {
      return false;
    } else {
      pC.current = true;
      setCurrentCountry(null);

      d3.select("#map-tooltip").style("display", "none");
    }
  };

  const resetMap = () => {
    panzoom.current.setBeforePan(null);

    panzoom.current.pan(startPan);
    panzoom.current.zoom(1);
    panzoom.current.setBeforePan(beforePan);
  };

  useEffect(() => {
    if (!mapLoaded) {
      setMapLoaded(true);

      d3.xml(svgPath).then(async (data) => {
        setSvgMap(
          await d3.select("#svg-container").node()?.append(data.documentElement)
        );

        if (document.getElementById("svg-container")) {
          panzoom.current = svgPanZoom(
            document.getElementById("svg-container").children[0],
            {
              zoomScaleSensitivity: 0.5,
              minZoom: 1,
              maxZoom: 20,
              center: 1,
              fit: 1,
              beforePan: () => {
                console.log(panzoom.current.getZoom());
                if (Math.round(panzoom.current.getZoom()) === 1) {
                  return false;
                } else {
                  pC.current = true;
                  setCurrentCountry(null);

                  d3.select("#map-tooltip").style("display", "none");
                }
              },
              onPan: () => {
                setTimeout(() => (pC.current = false), 500);
              },

              onZoom: () => {
                panzoom.current.pan(startPan);
                if (panzoom.current.getZoom() * 100 <= 101) {
                  resetMap();
                }
              }
            }
          );
          setMainMapLoaded(true);
          setStartPan(panzoom.current.getPan());
        }
      });
    }
  }, []);

  const ReferenceBar = () => {
    if (!countries.length) return null;

    let divisions = [
      steps[0],
      steps[1] * coef,
      steps[2] * coef,
      steps[3] * coef,
      steps[4] * coef,
      steps[5] * coef,
      steps[6] * coef,
      steps[7] * coef,
      steps[7] * coef + 1
    ];
    return (
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {divisions.map((n, index) => {
              if (n === 0)
                return (
                  <div
                    className="divTableCell"
                    style={{
                      backgroundColor: getColor(0),
                      border: "0"
                    }}
                  >
                    0
                  </div>
                );
              if (n > steps[7] * coef)
                return (
                  <div
                    className="divTableCell"
                    style={{
                      backgroundColor: getColor(steps[7] * coef + 1),
                      color: "white",
                      border: "0"
                    }}
                  >
                    &gt;{Math.floor(steps[7] * coef)}
                  </div>
                );

              return (
                <div
                  className="divTableCell"
                  style={{
                    backgroundColor: getColor(n),
                    ...(n > 200 * coef && { color: "white" })
                  }}
                >
                  {Math.floor(n)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div id="svg-container"></div>
      <ReferenceBar />
      <div style={{ position: "relative" }}>
        <div className="svg-map-zoom">
          <div
            className="svg-map-zoom__control svg-map-zoom__control--plus"
            onClick={(e) => panzoom.current.zoom(panzoom.current.getZoom() + 1)}
          >
            +
          </div>
          <div
            className="svg-map-zoom__control svg-map-zoom__control--minus"
            onClick={(e) => panzoom.current.zoom(panzoom.current.getZoom() - 1)}
          >
            -
          </div>
        </div>
        <div className="svg-map-reset" onClick={resetMap}>
          Reset map
        </div>
      </div>
      <div
        className="tooltip"
        id="map-tooltip"
        style={{ position: "absolute", display: "none" }}
      >
        {currentCountry && (
          <div id="country_name">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: ".6rem"
              }}
            >
              <FlagImg
                classes="entry__country-flag"
                code={currentCountry.country_code}
                name={currentCountry.name}
                style={{ marginRight: "1rem" }}
              />
              {currentCountry.name}
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "lighter"
              }}
            >
              {ths(currentCountry.n_approved)} Entries
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SvgMap;
