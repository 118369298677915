import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment
} from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

const SearchTag = ({
  name,
  id,
  currentSearch,
  setCurrentSearch,
  type,
  color
}) => {
  return (
    <div
      className={
        "search-and-tags-container__tag search-and-tags-container__tag--" +
        color
      }
    >
      {name}
      <span
        onClick={(event) =>
          setCurrentSearch({
            ...currentSearch,
            [type]: currentSearch[type].filter((ct) => ct.id !== id)
          })
        }
      >
        {" "}
        <span style={{ marginLeft: "5px", color: "#fff", cursor: "pointer" }}>
          ×
        </span>
      </span>
    </div>
  );
};

export default SearchTag;
