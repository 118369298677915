import React, { useState, useContext, useRef, useEffect } from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";
import { getTags } from "../redux/actions/related";
import { Link } from "react-router-dom";
import { ths } from "../Lib/WMHelper";

const TagList = ({ isLoggedIn, tags, getTags }) => {
  useEffect(() => {
    if (!tags || !tags.length) getTags();
  }, []);

  return (
    <MainLayout showControls={isLoggedIn}>
      <section class="cards-container all-tags">
        <h1 class="terms-and-conditions__title" style={{ marginTop: "50px" }}>
          All topics
        </h1>
        <div class="card card--100">
          <div class="card-row">
            <p class="card-row__text">
              {tags &&
                tags.length > 0 &&
                tags.map((t) => (
                  <div>
                    <Link className="custom-style" to={"/topics/" + t.slug}>
                      {t.name} ({ths(t.n_approved)})
                    </Link>
                  </div>
                ))}
            </p>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current,
  tags: state.related.tags
});

const mapDispatchToProps = (dispatch) => ({
  getTags: () => dispatch(getTags())
});

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
