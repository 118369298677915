import React, { Fragment, useEffect, useState } from "react";

import { ResponsiveBar } from "@nivo/bar";
import { ths } from "../Lib/WMHelper";

function findMaxMax(arr) {
  let min = arr[0].y,
    max = arr[0].y;

  for (let i = 1, len = arr.length; i < len; i++) {
    let v = arr[i].y;
    min = v < min ? v : min;
    max = v > max ? v : max;
  }

  return [min, max];
}

const getSourceTypesStats = (sts) => {
  let total = sts.reduce((total, obj) => total + obj.n_approved, 0);
  let colors = [
    "#F94144",
    "#F3722C",
    "#F8961E",
    "#F9C74F",
    "#90BE6D",
    "#43AA8B",
    "#577590",
    "#4D908E"
  ];
  let official = sts

    .map((l) => ({
      source_type: l.code,
      name: l.name,
      label: "AAAA",
      color: "#702aee",
      qty: l.n_approved
    }))
    .filter(
      (st) => st.source_type !== "MOU" && st.name.indexOf("Government") === -1
    )
    .sort((a, b) => b.qty - a.qty)
    .slice(0, 5);

  let gov = sts

    .map((l) => ({
      name: l.name,
      source_type: l.code,
      color: "#702aee",
      qty: l.n_approved
    }))
    .filter((a) => a.name.indexOf("Government") !== -1);

  let govObj = {
    source_type: "GOV",
    name: "GOV",
    color: "#702aee",
    qty: 0
  };

  gov.forEach((st) => {
    govObj.qty += st.qty;
  });

  console.log(
    official
      .concat([
        govObj,
        {
          source_type: "Others",
          name: "Others",
          color: "#702aee",
          qty: sts
            .filter(
              (st) =>
                st.code !== "MOU" &&
                st.name.indexOf("Government") === -1 &&
                !official.find((s) => s.source_type === st.code)
            )
            .reduce((acc, obj) => acc + obj.n_approved, 0)
        }
      ])
      .sort((a, b) => a.qty - b.qty)
      .map((st, i) => ({ ...st, color: colors[i] }))
  );

  return official
    .concat([
      govObj,
      {
        source_type: "Others",
        name: "Others",
        color: "#702aee",
        qty: sts
          .filter(
            (st) =>
              st.code !== "MOU" &&
              st.name.indexOf("Government") === -1 &&
              !official.find((s) => s.source_type === st.code)
          )
          .reduce((acc, obj) => acc + obj.n_approved, 0)
      }
    ])
    .sort((a, b) => a.qty - b.qty)
    .map((st, i) => ({ ...st, color: colors[i] }))
    .filter((c) => c.qty > 0);
};

const SourceTypesStats = ({ data, sourceTypes }) => {
  const [sts, setSts] = useState(null);
  useEffect(() => {
    if (!sts) setSts(getSourceTypesStats(data));
  }, [sourceTypes]);
  return (
    <div style={{ height: "400px" }}>
      {" "}
      {sts && sts.length > 0 ? (
        <ResponsiveBar
          label={(d) => (d.data.qty ? ths(d.data.qty) : 0)}
          data={sts}
          keys={["qty"]}
          indexBy="source_type"
          margin={{ top: -20, right: 60, bottom: 0, left: 60 }}
          padding={0.65}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={(d) => {
            return d.data.color;
          }}
          borderColor="#702aee"
          axisTop={null}
          layout="horizontal"
          maxValue={
            sts.length &&
            Math.ceil(sts.reduce((p, c) => (p.qty > c.qty ? p : c)).qty / 10) *
              10
          }
          axisRight={null}
          axisBottom={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: (d) => {
              console.log(d);
              return [d.data.st];
            },
            legendPosition: "middle",
            legendOffset: 32,
            format: function (value) {
              return value ? ths(value) : value;
            }
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -60
          }}
          tooltip={({ id, value, color, indexValue }) => (
            <strong style={{ color }}>
              {sourceTypes.find((st) => st.code === indexValue)
                ? sourceTypes.find((st) => st.code === indexValue).name
                : indexValue === "GOV"
                ? "Government"
                : "Others"}
              : {value ? ths(value) : value}
            </strong>
          )}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={"#FFF"}
          animate={false}
          motionStiffness={90}
          motionDamping={15}
        />
      ) : (
        "No entries from other sources"
      )}
    </div>
  );
};

export default SourceTypesStats;
