import React from "react";
import Search from "./Entries/Search";
import AllTopics from "./Pages/AllTopics";
import ContactUs from "./Pages/ContactUs";
import Dashboard from "./Pages/Dashboard";
import HelpFaq from "./Pages/HelpFaq";
import MyFolders from "./Pages/MyFolders";
import ProfileJur from "./Pages/ProfileJur";
import ProfileLanguage from "./Pages/ProfileLanguage";
import ProfileTag from "./Pages/ProfileTag";
import PublicHome from "./Pages/PublicHome";
import SavedSearch from "./Pages/SavedSearch";
import SearchResults from "./Pages/SearchResults";
import TagList from "./Pages/TagList";
import Terms from "./Pages/Terms";
import StopSearch from "./Pages/StopSearch";
import MyProfile from "./Pages/MyProfile";

const routes = [
  {
    name: "PublicHome",
    path: "/",
    exact: true,

    main: () => <PublicHome />
  },
  {
    name: "ProfileLanguage",
    path: "/verify/:g_uid/:hash",
    exact: true,
    admin: false,
    main: () => <PublicHome />
  },
  {
    name: "Dashboard",
    path: "/home",
    exact: true,
    admin: true,
    main: () => <Dashboard />
  },
  {
    name: "MyProfile",
    path: "/my-profile",
    exact: true,
    admin: true,
    main: () => <MyProfile />
  },
  {
    name: "SearchResults",
    path: "/search-results",
    exact: true,
    admin: true,
    main: () => <SearchResults />
  },
  {
    name: "SearchResults",
    path: "/stop-search/:stop_hash",
    exact: true,
    admin: true,
    main: () => <StopSearch />
  },
  {
    name: "SavedSearch",
    path: "/saved-searches",
    exact: true,
    admin: true,
    main: () => <SavedSearch />
  },
  {
    name: "SavedSearch",
    path: "/saved-searches/:hash_link",
    exact: true,
    admin: true,
    main: () => <SearchResults />
  },
  {
    name: "MyFolders",
    path: "/my-folders",
    exact: true,
    admin: true,
    main: () => <MyFolders />
  },
  {
    name: "MyFolders",
    path: "/all-topics",
    exact: true,
    admin: true,
    main: () => <TagList />
  },

  {
    name: "ProfileTag",
    path: "/topics/:slug",
    exact: true,
    admin: true,
    main: () => <ProfileTag />
  },
  {
    name: "AllTopics",
    path: "/topics",
    exact: true,
    admin: true,
    main: () => <AllTopics />
  },
  {
    name: "ProfileJur",
    path: "/jurisdictions/:slug",
    exact: true,
    admin: true,
    main: () => <ProfileJur />
  },
  {
    name: "ProfileLanguage",
    path: "/languages/:slug",
    exact: true,
    admin: true,
    main: () => <ProfileLanguage />
  },
  {
    name: "Search",
    path: "/entries",
    exact: true,
    admin: true,
    main: () => <Search />
  },
  {
    name: "ContactUs",
    path: "/contact-us",
    exact: true,
    admin: false,
    main: () => <ContactUs />
  },
  {
    name: "Terms",
    path: "/terms-and-conditions",
    exact: true,
    admin: false,
    main: () => <Terms />
  },
  {
    name: "HelpFaq",
    path: "/help-faq",
    exact: true,
    admin: false,
    main: () => <HelpFaq />
  }
];

export default routes;
