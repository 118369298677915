import React, { Fragment } from "react";

const FlagImg = ({ classes, code, name }) => {
  return (
    <Fragment>
      {code ? (
        <img
          src={"/assets/img/flags/" + code.toLowerCase() + ".svg"}
          alt={name}
          title={name}
          class={classes}
        />
      ) : (
        <div>ERROR</div>
      )}
    </Fragment>
  );
};

export default FlagImg;
