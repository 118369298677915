import React, { Fragment, useEffect, useState } from "react";

import { ResponsivePie } from "@nivo/pie";
import { ths } from "../Lib/WMHelper";
import { useHistory } from "react-router";

let lastColor = "#fff";

const getLanguageStats = (languages) => {
  let total = languages.reduce((total, obj) => total + obj.n_approved, 0);
  let official = languages
    .map((l) => ({
      id: l.name,
      name: l.name,
      value: Math.round((l.n_approved / total) * 100),
      total: l.n_approved
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);

  let totalO = official.reduce((acc, obj) => acc + obj.total, 0);
  return total - totalO > 0
    ? official.concat([
        {
          id: "Others",
          name: "Others",
          value: Math.round(((total - totalO) / total) * 100),
          total: total - totalO
        }
      ])
    : official;
};

const LanguagesStats = ({
  languages,
  gotoSearch,
  setCurrentSearch,
  currentSearch
}) => {
  const history = useHistory();
  const [currentPercentage, setCurrentPercentage] = useState(null);
  let data = getLanguageStats(languages);
  return (
    <div style={{ height: "400px", position: "relative" }}>
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 30, bottom: 80, left: 30 }}
        innerRadius={0.75}
        padAngle={0.7}
        startAngle={60}
        endAngle={420}
        enableLabels={true}
        enableSliceLabels={false}
        valueFormat={(value) => `${value}%`}
        legendFormat={(value) => `${value}%`}
        cornerRadius={0}
        colors={[
          "#577590",
          "#4D908E",
          "#43AA8B",
          "#90BE6D",
          "#F9C74F",
          "#F8961E",
          "#F3722C",
          "#F94144"
        ]}
        borderWidth={0}
        enableRadialLabels={false}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#222"
        onMouseEnter={(_data, event) => {
          lastColor = event.target.style.fill;
          event.target.style.fillOpacity = "0.4";
          //event.target.style.transform = "scale(1.03)";
          event.target.style.cursor = "pointer";
          setCurrentPercentage({
            number: data.find((l) => l.id === _data.id).total,
            language: _data.id
          });
        }}
        onMouseLeave={(_data, event) => {
          //event.target.style.transform = "scale(1)";
          event.target.style.fillOpacity = "1";
          event.target.style.fill = lastColor;
          setCurrentPercentage(null);
        }}
        onClick={(d) => {
          if (d.label === "Others") return;
          gotoSearch
            ? setCurrentSearch({
                ...currentSearch,
                languages: [languages.find((t) => t.name === d.label)]
              }).then(() => history.push("/search-results"))
            : history.push(
                "/languages/" + languages.find((t) => t.name === d.label).slug
              );
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        legends={[
          {
            anchor: "bottom",
            direction: "column",
            justify: false,
            translateX: -80,
            translateY: 80,
            itemsSpacing: 5,
            itemWidth: 200,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            textDecoration: "underline",
            cursor: "pointer",
            onClick: (d) => alert(d.id),
            format: (value) =>
              `${Number(value).toLocaleString("ru-RU", {
                minimumFractionDigits: 2
              })} ₽`,

            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000"
                }
              },
              {
                on: "click",
                style: {
                  itemTextColor: "#000"
                }
              }
            ]
          }
        ]}
      />
      {currentPercentage && (
        <div
          style={{
            position: "absolute",
            left: "49%",
            top: "40%",
            textAlign: "center",
            transform: "translate(-45%, -50%)",
            fontSize: "50px",
            fontWeight: "bold",
            ...(!currentPercentage && { display: "none" })
          }}
        >
          <div
            style={{
              fontSize: "26px",
              color: "gray"
            }}
          >
            {currentPercentage.language}
          </div>
          {ths(currentPercentage.number)}

          <div
            style={{
              fontSize: "26px",
              color: "gray"
            }}
          >
            entries
          </div>
        </div>
      )}
      <div
        style={{
          position: "relative",
          top: 0,
          right: 30,
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 12,
          color: "#222",
          // background: "#FFFFFF33",
          textAlign: "center",
          // This is important to preserve the chart interactivity
          pointerEvents: "none"
        }}
      ></div>
    </div>
  );
};

export default LanguagesStats;
