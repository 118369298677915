import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import { utc } from "moment-timezone";
import { Fragment } from "react";

const SaveFolderPopup = ({
  isOpen,
  setIsOpen,
  savedFolders,
  addSavedFolder,
  updateSavedFolder,
  entries
}) => {
  const [name, setName] = useState("");

  const [existingFolder, setExistingFolder] = useState(null);
  const [errors, setErrors] = useState({});
  const [saved, setSaved] = useState(false);
  const [articles, setArticles] = useState([]);

  const recaptchaRef = React.createRef();
  const history = useHistory();

  let btnRef = useRef();
  const Auth = useContext(AuthContext);
  function closeModal() {
    setName("");
    setExistingFolder(null);
    setSaved(false);
    setIsOpen(false);
  }

  const handleForm = async (e) => {
    e.preventDefault();
    if (name || existingFolder) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.setAttribute("value", "Saving...");
      }
      if (name) {
        addSavedFolder(articles, name).then(() => {
          setSaved(true);
        });
      } else if (existingFolder) {
        updateSavedFolder(articles, existingFolder.id).then(() => {
          setSaved(true);
        });
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (isOpen && isOpen.h) {
      setArticles([isOpen]);
    } else if (isOpen === true) {
      setArticles(entries);
    }
  }, [isOpen]);

  useEffect(() => {
    if (entries && entries.length) setArticles(entries);
  }, [entries]);

  useEffect(() => {
    console.log(articles);
  }, [articles]);

  useEffect(() => {
    if (name) {
      setExistingFolder(null);
    }
  }, [name]);

  useEffect(() => {
    console.log(existingFolder);
    if (existingFolder) {
      setName("");
    }
  }, [existingFolder]);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="popup-box">
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>

        {!saved ? (
          <Fragment>
            {" "}
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/folder-shared.svg" height="48px" />
            </p>
            <h3 className="popup-box__subtitle">
              Saving{" "}
              {articles
                ? articles.length === 1
                  ? "this entry from " + articles[0].s
                  : articles.length + " entries"
                : ""}{" "}
              to folder
            </h3>
            <p className="popup-box__text">
              Please note that the ILGA World Monitor does not store third party
              content.
              <br />
              This function allows you to save the reference to the original
              source.
              <br />
              If the original source is lost or moved, the reference will no
              longer be valid.
            </p>
            <form className="save-search-criteria-form">
              <span>Save to New Folder</span>

              <input
                className="save-search-criteria-form__input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="savename"
                type="text"
                placeholder="Folder name"
              />

              <span style={{ marginBottom: ".6rem" }}>
                Save to Existing Folder
              </span>

              <div style={{ width: "100%" }}>
                <Select
                  autosize={true}
                  options={
                    savedFolders && savedFolders.length > 0
                      ? savedFolders
                      : [{ id: "", name: "Loading...", isDisabled: true }]
                  }
                  placeholder={"Existing folder..."}
                  onChange={(value) => setExistingFolder(value)}
                  getOptionLabel={(option) =>
                    option.name +
                    " (" +
                    utc(
                      option.updated_at ? option.updated_at : option.created_at
                    ).format("DD MMM YYYY") +
                    ")"
                  }
                  getOptionValue={(option) => option.id}
                  value={existingFolder || null}
                  width="100%"
                  className="saved-search-select"
                />
              </div>

              <br />
              <button
                ref={btnRef}
                className="login-form__submit"
                onClick={handleForm}
              >
                Save
              </button>
              {errors.message && (
                <div className="login-form-error">{errors.message} </div>
              )}
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              <img src="/assets/svgs/check_circle.svg" height="48px" />
            </p>
            <p className="popup-box__text popup-box__text--narrow">
              Your {articles.length === 1 ? "entry is" : "entries are"} now
              stored in {name ? name : existingFolder.name}
            </p>
            <br />
            <br />
            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={(e) => history.push("/my-folders")}
              style={{ backgroundColor: "#4D6A85" }}
            >
              Go to folder
            </button>
            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={closeModal}
            >
              Close
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SaveFolderPopup;
