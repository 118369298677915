import axios from "axios";

export const setCurrentSearch = (search, filter = false) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SEARCH", payload: true });
    try {
      dispatch({ type: "SET_CURRENT_SEARCH", payload: { search, filter } });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const resetCurrentSearch = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SEARCH", payload: true });
    try {
      dispatch({ type: "RESET_CURRENT_SEARCH", payload: null });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const getSavedSearches = () => {
  return async (dispatch) => {
    //dispatch({ type: "LOADING_SEARCH", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/saved_searches"
      );
      dispatch({ type: "GET_SAVED_SEARCHES", payload: responseData.data });
      //dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      //dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const addSavedSearch = (search, name, update_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/save_search",
        { search, name, update_user }
      );
      dispatch({ type: "ADD_SAVED_SEARCH", payload: responseData.data });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const updateSavedSearch = (search, id, update_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/update_search",
        { search, id, update_user }
      );
      dispatch({
        type: "UPDATE_SAVED_SEARCH",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const readSavedSearch = (search_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/read_search",
        { search_id }
      );
      dispatch({
        type: "UPDATE_SAVED_SEARCH",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const deleteSavedSearch = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/delete_search",
        { id }
      );
      dispatch({
        type: "DELETE_SAVED_SEARCH",
        payload: { id }
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const stopSavedSearch = (hash) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/stop_search",
        { hash }
      );
      dispatch({
        type: "STOP_SAVED_SEARCH",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const startSavedSearch = (hash) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/start_search",
        { hash }
      );
      dispatch({
        type: "STOP_SAVED_SEARCH",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const executeSearch = (search) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_SEARCH", payload: true });

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/search",
        search
          ? {
              query: search.query,
              selects: search
            }
          : {
              query: getState().search.current.query,
              selects: getState().search.current
            }
      );
      dispatch({ type: "EXECUTE_SEARCH", payload: responseData.data });
      dispatch({ type: "FILTER_PENDING_FALSE", payload: null });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const fetchDaughters = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SEARCH", payload: true });

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/articles/fetchDaughters/" +
          article.id
      );
      dispatch({
        type: "FETCH_DAUGHTERS",
        payload: { mother: article.id, daughters: responseData.data.daughters }
      });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const toggleDaughters = (article, group) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DAUGHTERS", payload: article.id });
    try {
      if (!article.daughters) {
        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/articles/fetchDaughters/" +
            article.id
        );
        dispatch({
          type: "LOAD_SHOW_DAUGHTERS",
          payload: {
            mother: article.id,
            daughters: responseData.data.daughters,
            group
          }
        });
      } else {
        dispatch({
          type: "SHOW_DAUGHTERS",
          payload: { mother: article.id, group }
        });
      }

      dispatch({ type: "LOADING_DAUGHTERS", payload: null });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_SEARCH", payload: err });
      dispatch({ type: "LOADING_DAUGHTERS", payload: null });
    }
  };
};

export const getSavedFolders = () => {
  return async (dispatch) => {
    //dispatch({ type: "LOADING_SEARCH", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/saved_folders"
      );
      dispatch({ type: "GET_SAVED_FOLDERS", payload: responseData.data });
      //dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      //dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const setArticleUsed = (folder_id, article_id, used) => {
  return async (dispatch) => {
    //dispatch({ type: "LOADING_SEARCH", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/mark_used",
        { folder_id, article_id, used }
      );

      //dispatch({ type: "LOADING_SEARCH", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SEARCH", payload: err });
      //dispatch({ type: "LOADING_SEARCH", payload: false });
    }
  };
};

export const addSavedFolder = (entries, name) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/save_folder",
        { entries: entries.map((e) => e.id), name }
      );
      dispatch({ type: "ADD_SAVED_FOLDER", payload: responseData.data });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const updateSavedFolder = (entries, id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/update_folder",
        { entries: entries.map((e) => e.id), id }
      );
      dispatch({
        type: "UPDATE_SAVED_FOLDER",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const removeFromSavedFolder = (id, article_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/remove_from_folder",
        { article_id, id }
      );
      dispatch({
        type: "DELETE_FROM_SAVED_FOLDER",
        payload: { article_id, id }
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const deleteSavedFolder = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/delete_folder",
        { id }
      );
      dispatch({
        type: "DELETE_SAVED_FOLDER",
        payload: { id }
      });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};
