import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import { auth, firebase } from "./firebase";
import { createStore, applyMiddleware, compose } from "redux";
import { connect, Provider } from "react-redux";

import rootReducer from "./redux/reducers.js";

import thunkMiddleware from "redux-thunk";
import Axios from "axios";
import setAuthToken from "./Util/setAuthToken";
import Test from "./Test";
import { setLoggedIn, setUser } from "./redux/actions/users";
import useInterval from "Lib/useInterval";

export const AuthContext = React.createContext(null);

function App() {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
        shouldHotReload: false
      })) ||
    compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );

  useInterval(() => {
    if (store.getState().users.logged_in) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          console.log("new token", token);
          setAuthToken(token);
        });
    }
  }, 1500000);

  useEffect(() => {
    // idToken comes from the client app
    auth.onAuthStateChanged(async (user) => {
      if (user && firebase.auth().currentUser) {
        if (user.emailVerified) {
          let token = await user.getIdToken();
          setAuthToken(token);
          try {
            let res = await Axios.post(
              process.env.REACT_APP_BACKEND_URL + "/users/login",
              {
                g_uid: firebase.auth().currentUser.uid,
                token: await user.getIdToken()
              }
            );
            store.dispatch(setUser(res.data));
            store.dispatch(setLoggedIn(true));
          } catch (e) {
            store.dispatch(setLoggedIn(false));
            store.dispatch(setUser(null));
          }
        } else {
          store.dispatch(setLoggedIn(false));
          store.dispatch(setUser(null));
        }
      } else {
        store.dispatch(setLoggedIn(false));
        store.dispatch(setUser(null));
      }
    });
    auth.onIdTokenChanged(async (user) => {
      console.log("changed!!!");
      if (user && firebase.auth().currentUser) {
        let token = await user.getIdToken();
        console.log("token");
        setAuthToken(token);
      } else {
        store.dispatch(setLoggedIn(false));
        store.dispatch(setUser(null));
      }
    });
  }, [store.getState().users]);

  return (
    <Provider store={store}>
      <Fragment>
        <div className="Loading">
          <div className="Loading-progress"></div>
        </div>
        <Main isLoggedIn={store.getState().users.logged_in} />
      </Fragment>
    </Provider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
