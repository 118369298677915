import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import CountriesStats from "../Components/CountriesStats";
import EntriesList from "../Components/EntriesList";
import LanguagesStats from "../Components/LanguagesStats";
import SearchBar from "../Components/SearchBar";
import SourceTypesStats from "../Components/SourceTypesStats";
import SvgMap from "../Components/SvgMap";
import { AuthContext } from "../index";
import MainLayout from "../Layout/MainLayout";
import { getLatest } from "../redux/actions/articles";
import {
  getJurisdictions,
  getLanguages,
  getSourceNames,
  getSourceTypes,
  getTags,
  getTagStats
} from "../redux/actions/related";
import { resetCurrentSearch, setCurrentSearch } from "../redux/actions/search";

const ProfileTag = ({
  match,

  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  latest,
  getLatest,
  getTagStats,
  loading_tag_stats
}) => {
  let { slug } = useParams();
  const history = useHistory();

  const [tag, setTag] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!tags || !tags.length) getTags();
    if (tag && tag.name && !loaded) {
      setLoaded(true);

      if (!latest || !latest.mou.tag[tag.name]) getLatest(1, "tag", tag.name);
      if (!latest || !latest.nomou.tag[tag.name]) getLatest(0, "tag", tag.name);
    }
  }, []);

  useEffect(() => {
    if (tag && tag.name && !loaded) {
      setLoaded(true);
      if (!latest || !latest.mou.tag[tag.name]) getLatest(1, "tag", tag.name);
      if (!latest || !latest.nomou.tag[tag.name]) getLatest(0, "tag", tag.name);
      if (tag && !tag.stats && !loading_tag_stats) {
        getTagStats(tag.id).then(console.log(tag));
      }
    }
  }, [tag]);

  useEffect(() => {
    if (tags.length) {
      let tagTmp = tags.find((t) => t.slug === slug);
      if (tagTmp) {
        setTag(tagTmp);
        setCurrentSearch({ ...currentSearch, tags: [tagTmp] });
      } else history.push("/home");
    }
  }, [tags]);

  const Auth = useContext(AuthContext);
  return (
    <MainLayout showControls={true}>
      {" "}
      <SearchBar tags={tags} />
      {tag && tags.length && (
        <Fragment>
          <section class="topic-title">
            <h1>{tag.name}</h1>
            <p>Topic profile</p>
            <hr></hr>
          </section>
          <section class="cards-container">
            <div class="card card--50">
              <h3 class="card__title">Media outlets reporting on {tag.name}</h3>
              <div class="card__content">
                <EntriesList
                  special="tag"
                  isMou={true}
                  tag={tag}
                  loadedArticles={tag && latest.mou.tag[tag.name]}
                />
              </div>
              {latest.mou.tag[tag.name] !== -1 && (
                <Link
                  to="/search-results"
                  class="card__more"
                  onClick={(e) =>
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: [
                        sourceTypes.find((st) => st.code === "MOU")
                      ]
                    })
                  }
                >
                  Search more entries from media outlets &gt;
                </Link>
              )}
            </div>
            <div class="card card--50">
              <h3 class="card__title">Other sources reporting on {tag.name}</h3>
              <div class="card__content">
                <EntriesList
                  special="tag"
                  isMou={false}
                  tag={tag}
                  loadedArticles={tag && latest.nomou.tag[tag.name]}
                />
              </div>

              {latest.nomou.tag[tag.name] !== -1 && (
                <Link
                  to="/search-results"
                  class="card__more"
                  onClick={(e) => {
                    console.log(sourceTypes.slice(1));
                    setCurrentSearch({
                      ...currentSearch,
                      source_types: sourceTypes.filter(
                        (st) => st.code !== "MOU"
                      )
                    });
                  }}
                >
                  Search more entries &gt;
                </Link>
              )}
            </div>
            {tag && tag.stats && tag.stats.jurisdictions && (
              <div class="card card--55">
                <h3 class="card__title">
                  Entries on {tag.name} by jurisdiction
                </h3>
                <div class="card__content">
                  <SvgMap
                    countries={jurisdictions.map((j) => ({
                      ...j,
                      n_approved: tag.stats.jurisdictions.find(
                        (jj) => jj.jurisdiction_id === j.id
                      )
                        ? tag.stats.jurisdictions.find(
                            (jj) => jj.jurisdiction_id === j.id
                          ).n_approved
                        : 0
                    }))}
                    setMainMapLoaded={setMapLoaded}
                    coef_ini={Math.max.apply(
                      Math,
                      tag.stats.jurisdictions.map(function (o) {
                        return o.n_approved;
                      })
                    )}
                    gotoSearch={true}
                    setCurrentSearch={setCurrentSearch}
                    currentSearch={currentSearch}
                  />
                </div>
              </div>
            )}
            {tag.stats && tag.stats.jurisdictions && (
              <div class="card card--45">
                <h3 class="card__title">
                  Total entries on {tag.name} by jurisdiction
                </h3>
                <div class="card__content">
                  <CountriesStats
                    countries={jurisdictions.map((j) => ({
                      ...j,
                      n_approved: tag.stats.jurisdictions.find(
                        (jj) => jj.jurisdiction_id === j.id
                      )
                        ? tag.stats.jurisdictions.find(
                            (jj) => jj.jurisdiction_id === j.id
                          ).n_approved
                        : 0
                    }))}
                    gotoSearch={true}
                    setCurrentSearch={setCurrentSearch}
                    currentSearch={currentSearch}
                  />
                </div>
              </div>
            )}
            {tag && tag.stats && (
              <div class="card card--40 card--45md">
                <span class="card__subtitle">Top 5</span>
                <h3 class="card__title card__title--bordered">
                  Entries on {tag.name} by language
                </h3>
                <div class="card__content">
                  {tag.stats.languages && tag.stats.languages.length ? (
                    <div class="card__content">
                      <LanguagesStats
                        gotoSearch={true}
                        setCurrentSearch={setCurrentSearch}
                        currentSearch={currentSearch}
                        languages={tag.stats.languages.map((l) => ({
                          ...languages.find((l2) => l2.id === l.language_id),
                          n_approved: l.n_approved
                        }))}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            )}
            <div class="card card--60 card--55md">
              <span class="card__subtitle">Non Media-Outlet Sources</span>
              <h3 class="card__title card__title--bordered">
                Entries on {tag.name} by source type
              </h3>
              {sourceTypes &&
              sourceTypes.length > 0 &&
              tag.stats &&
              tag.stats.source_types ? (
                <div class="card__content">
                  <SourceTypesStats
                    data={tag.stats.source_types.map((st) => ({
                      ...sourceTypes.find(
                        (st2) => st2.id === st.source_type_id
                      ),
                      n_approved: st.n_approved
                    }))}
                    sourceTypes={sourceTypes}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </section>
        </Fragment>
      )}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  latest: state.articles.latest,
  loading_tag_stats: state.related.loading_tag_stats
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getLatest: (mou, type, id) => dispatch(getLatest(mou, type, id)),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getTagStats: (tag_id) => dispatch(getTagStats(tag_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileTag);
