import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import { utc } from "moment-timezone";
import { Fragment } from "react";

const SaveSearchPopup = ({
  isOpen,
  setIsOpen,
  savedSearches,
  addSavedSearch,
  updateSavedSearch,
  currentSearch
}) => {
  const [name, setName] = useState("");
  const [existingSearch, setExistingSearch] = useState("");
  const [errors, setErrors] = useState({});
  const [saved, setSaved] = useState(false);

  const recaptchaRef = React.createRef();
  const history = useHistory();

  let btnRef = useRef();
  let updateRef = useRef();
  const Auth = useContext(AuthContext);
  function closeModal() {
    setSaved(false);
    setIsOpen(false);
  }

  const handleForm = async (e) => {
    e.preventDefault();
    if (existingSearch || name) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.setAttribute("value", "Saving...");
      }
      if (name) {
        addSavedSearch(
          currentSearch,
          name,
          updateRef.current.checked ? "1" : "0"
        )
          .then(() => {
            setName("");
            setSaved(true);
          })
          .catch((e) => {
            console.error("Error saving search", currentSearch);
            console.error(e);
          });
      } else if (existingSearch) {
        updateSavedSearch(currentSearch, existingSearch.id, updateRef.current.checked?"1":"0")
          .then(() => {
            setExistingSearch("");
            setSaved(true);
          })
          .catch((e) => {
            console.error("Error updating search", currentSearch);
            console.error(e);
          });
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="popup-box">
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>
        <p style={{ textAlign: "center" }}>
          <img src="/assets/svgs/bookmark-add.svg" height="48px" />
        </p>
        <h3 className="popup-box__subtitle">Save search criteria</h3>
        {!saved ? (
          <form className="save-search-criteria-form">
            <span>New</span>

            <input
              className="save-search-criteria-form__input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="savename"
              type="text"
              placeholder="Saved search name"
            />

            <span style={{ marginBottom: ".6rem" }}>Replace Existing</span>

            <div style={{ width: "100%" }}>
              <Select
                autosize={true}
                options={
                  savedSearches && savedSearches.length > 0
                    ? savedSearches
                    : [{ id: "", name: "Loading...", isDisabled: true }]
                }
                placeholder={"Existing saved search"}
                onChange={(value) => setExistingSearch(value)}
                getOptionLabel={(option) =>
                  option.name +
                  " (" +
                  utc(option.updated_at).format("DD MMM YYYY") +
                  ")"
                }
                getOptionValue={(option) => option.id}
                value={existingSearch || null}
                width="100%"
                className="saved-search-select"
              />
            </div>

            <div className="popup-save-radio">
              <input
                type="checkbox"
                name="update_user"
                value="1"
                ref={updateRef}
              />{" "}
              Keep me posted{" "}
              <span data-tip="By selecting this option, you will receive a weekly email with a digest showing all new entries that match your search criteria. The digests allow you to consult sources directly without having to log in to the ILGA World Monitor.">
                <img
                  style={{ verticalAlign: "bottom" }}
                  src="/assets/svgs/tooltip.svg"
                />
              </span>
            </div>

            <br />
            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={handleForm}
            >
              Save
            </button>
            {errors.message && (
              <div className="login-form-error">{errors.message} </div>
            )}
          </form>
        ) : (
          <Fragment>
            <p className="popup-box__text popup-box__text--narrow">
              Your search has been saved
            </p>
            <button
              ref={btnRef}
              className="login-form__submit"
              onClick={closeModal}
            >
              Close
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SaveSearchPopup;
