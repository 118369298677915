export const ths = (n) => {
  if (typeof n === "number") {
    n += "";
    var x = n.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "ʼ" + "$2");
    }
    return x1 + x2;
  } else {
    return n;
  }
};

export const jurSpecialName = (j) => {
  return j.id === "REG-10" ||
    j.id === "REG-11" ||
    j.id === "REG-12" ||
    j.id === "REG-13" ||
    j.id === "REG-14" ||
    j.id === "REG-4"
    ? j.name + " (" + j.name + " news only)"
    : j.id === "REG-1" ||
      j.id === "REG-2" ||
      j.id === "REG-3" ||
      j.id === "REG-5" ||
      j.id === "REG-6" ||
      j.id === "REG-7" ||
      j.id === "REG-8" ||
      j.id === "REG-9"
    ? j.name + " (regional news only)"
    : j.id === "REG-4"
    ? j.name + " (global news only)"
    : j.name;
};
