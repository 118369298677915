import { combineReducers } from "redux";
import search from "./reducers/search";
import related from "./reducers/related";
import articles from "./reducers/articles";
import users from "./reducers/users";

export default combineReducers({
  search,
  related,
  articles,
  users
});
