import React, { Fragment, useEffect } from "react";

import { AuthContext } from "../index";
import "./MainLayout.css";
import "./MainLayoutExtra.css";

import { Link, useHistory } from "react-router-dom";
import LoginForm from "../Components/LoginForm";

import { auth, firebase } from "../firebase";
import SignupForm from "../Components/SignupForm";

import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";

function PublicHomeLayout({
  children,
  showControls,
  isLoggedIn,
  user,
  openLogin,
  setOpenLogin
}) {
  const history = useHistory();
  const location = useLocation();

  const [loginIsOpen, setLoginIsOpen] = React.useState(
    location && location.state && location.state.openLogin
  );
  const [signupIsOpen, setSignupIsOpen] = React.useState(false);

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.removeItem("@token");

    history.push({ pathname: "/", state: "logout" });
  };

  useEffect(() => {
    console.log(location);
    if (openLogin) {
      setLoginIsOpen(true);
      setOpenLogin(false);
    }
  }, [openLogin]);

  return (
    <Fragment>
      <header className="header header--public-home">
        <div>&nbsp;</div>
        <h1 className="header__title">
          <img src="/assets/img/wm_logo_80.png" alt="Logo" className="logo" />
        </h1>
        <div className="header__buttons">
          {isLoggedIn && user ? (
            <Fragment>
              <a className="header__profile-link" href="#!">
                {(user.firstname + " " + user.lastname)
                  .toUpperCase()
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </a>
              <a className="header__logout-btn" href="#!" onClick={logout}>
                <img
                  src="assets/img/login-btn.png"
                  alt=""
                  className="header__logout-btn-image"
                />
              </a>
            </Fragment>
          ) : (
            <Fragment>
              <a
                className="header__profile-link"
                href="#"
                onClick={(e) => setLoginIsOpen(true)}
              >
                Login
              </a>
              <a
                className="header__profile-link"
                href="#"
                onClick={(e) => setSignupIsOpen(true)}
              >
                Signup
              </a>
            </Fragment>
          )}
        </div>
      </header>

      <section className="page-content">{children}</section>

      <footer className="footer">
        <div className="footer__links">
          <Link to="/contact-us">Contact Us</Link>&nbsp;|&nbsp;
          <a href="https://ilga.org/" target="_blank">
            ILGA World Website
          </a>
          &nbsp;|&nbsp;
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          &nbsp;|&nbsp;
          <Link to="/help-faq">Help & FAQ</Link>
        </div>
        <div className="footer__copyright">
          © 2017 - {new Date().getFullYear()} ILGA World - The International
          Lesbian, Gay, Bisexual, Trans and Intersex Association
        </div>
      </footer>
      {(!isLoggedIn || !user) && (
        <LoginForm isOpen={loginIsOpen} setIsOpen={setLoginIsOpen} />
      )}
      {(!isLoggedIn || !user) && (
        <SignupForm isOpen={signupIsOpen} setIsOpen={setSignupIsOpen} />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHomeLayout);
