import React from "react";
import PropTypes from "prop-types";
import { confirmable } from "react-confirm";
import { createConfirmation } from "react-confirm";

const Confirm = ({ show, proceed, confirmation, options }) => {
  return (
    <div className="popup-wrapper" onHide={() => proceed(false)} show={show}>
      <div className="popup-box popup-box--narrow">
        <button className="popup-box__close" onClick={() => proceed(false)}>
          &times;
        </button>

        <h3 className="popup-box__subtitle">{options.title}</h3>

        <p className="popup-box__text popup-box__text--narrow">{confirmation}</p>


        <div className="confirm-popup__btns-holder">
          <input
            className="confirm-popup__btn"
            onClick={() => proceed(false)}
            type="button"
            value="No"
          />

          <input
            className="confirm-popup__btn"
            type="button"
            onClick={() => proceed(true)}
            value="Yes"
          />
        </div>
      </div>
    </div>
  );
};

// create confirm function
export const confirm = createConfirmation(confirmable(Confirm), 0);

// This is optional. But wrapping function makes it easy to use.
export function confirmWrapper(confirmation, options = {}) {
  return confirm({ confirmation, options });
}
