import React, { useState, useContext, useRef, useEffect } from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";

const HelpFaq = ({ isLoggedIn }) => {
  useEffect(() => {}, []);

  return (
    <MainLayout showControls={isLoggedIn}>
      <section class="cards-container terms-and-conditions">
        <h1 class="terms-and-conditions__title">User Manual</h1>
        <div class="card card--100">

        </div>

        <h1 class="terms-and-conditions__title" style={{marginTop:'50px'}}>FAQ</h1>
        <div class="card card--100">
          <div class="card-row">
            <b class="card-row__header">How do I use this tool?</b>
            <p class="card-row__text">Like this bla bla bla</p>
          </div>

          <div class="card-row">
            <b class="card-row__header">What information do we collect?</b>
            <p class="card-row__text">
              Data we might collect includes your IP addresss, location
              information, name and email address. Additional information may be
              collected as you apply for scholarships, register for events, or
              apply for your organisation to become a member of ILGA World. This
              information is anonymous, meaning that online behaviour cannot be
              traced back to individuals. We set a 14-months retention period
              for this set of data that you send in this way. Click here for
              further information about Google's privacy policy.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">What information do we collect?</b>
            <p class="card-row__text">
              Data we might collect includes your IP addresss, location
              information, name and email address. Additional information may be
              collected as you apply for scholarships, register for events, or
              apply for your organisation to become a member of ILGA World. This
              information is anonymous, meaning that online behaviour cannot be
              traced back to individuals. We set a 14-months retention period
              for this set of data that you send in this way. Click here for
              further information about Google's privacy policy.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">What information do we collect?</b>
            <p class="card-row__text">
              Data we might collect includes your IP addresss, location
              information, name and email address. Additional information may be
              collected as you apply for scholarships, register for events, or
              apply for your organisation to become a member of ILGA World. This
              information is anonymous, meaning that online behaviour cannot be
              traced back to individuals. We set a 14-months retention period
              for this set of data that you send in this way. Click here for
              further information about Google's privacy policy.
            </p>
          </div>

          <div class="card-row">
            <b class="card-row__header">What information do we collect?</b>
            <p class="card-row__text">
              Data we might collect includes your IP addresss, location
              information, name and email address. Additional information may be
              collected as you apply for scholarships, register for events, or
              apply for your organisation to become a member of ILGA World. This
              information is anonymous, meaning that online behaviour cannot be
              traced back to individuals. We set a 14-months retention period
              for this set of data that you send in this way. Click here for
              further information about Google's privacy policy.
            </p>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HelpFaq);
