import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment
} from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";

import { getSavedSearches, stopSavedSearch } from "../redux/actions/search";
import { useParams } from "react-router";
import axios from "axios";

const StopSearch = ({
  isLoggedIn,
  stopSavedSearch,
  error,
  user,
  getSavedSearches
}) => {
  const [search, getSearch] = useState(null);
  let { stop_hash } = useParams();

  useEffect(() => {
    if (!search) {
      stopSavedSearch(stop_hash)
        .then(() => {})
        .catch(() => alert("Unexpected Error"));
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/articles/stop_search", {
          hash: stop_hash
        })
        .then((res) => {
          getSearch(res.data);
          getSavedSearches();
        });
    }
  }, []);

  return (
    <MainLayout showControls={isLoggedIn}>
      <section class="cards-container terms-and-conditions">
        {search && stop_hash !== "all" && (
          <Fragment>
            <h1 class="terms-and-conditions__title">Stop notifications</h1>

            <p class="card-row__text">
              Hello {user.firstname},<br />
              <br />
              As of today, you will not receive any more notifications for “
              {search.name}”.
              <br />
              <br />
              You can always reactivate your notifications in your “Saved Search
              Criteria” panel.
              <br />
              <br />
              Have a good day!
              <br />
              <br />
              Your ILGA World Monitor Team
            </p>
          </Fragment>
        )}

        {search && stop_hash === "all" && (
          <Fragment>
            <h1 class="terms-and-conditions__title">Stop all notifications</h1>

            <p class="card-row__text">
              Hello {user.firstname},<br />
              <br />
              As of today, you will not receive any more notifications from the
              ILGA World Monitor.
              <br />
              <br />
              You can always reactivate your notifications in your “Saved Search
              Criteria” panel.
              <br />
              <br />
              Have a good day!
              <br />
              <br />
              Your ILGA World Monitor Team
            </p>
          </Fragment>
        )}
        {!search && <div>Loading...</div>}
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current,
  error: state.search.error
});

const mapDispatchToProps = (dispatch) => ({
  stopSavedSearch: (hash) => dispatch(stopSavedSearch(hash)),
  getSavedSearches: () => dispatch(getSavedSearches())
});

export default connect(mapStateToProps, mapDispatchToProps)(StopSearch);
