import React, { useState, useContext, useRef, useEffect } from "react";
import ReactLogo from "../world.svg";
import * as d3 from "d3";
import MainLayout from "../Layout/MainLayout";
import svgPanZoom from "svg-pan-zoom";
import { connect } from "react-redux";
import { confirmWrapper, confirm } from "../Components/Confirm";
import { updateUser } from "redux/actions/users";
import { auth, firebase } from "../firebase";
import { useHistory } from "react-router";

import validator from "email-validator";
import axios from "axios";

const MyProfile = ({ isLoggedIn, user, updateUser }) => {
  const [editMail, setEditMail] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [alert, setAlert] = useState(null);

  let history = useHistory();

  useEffect(() => {
    setEditUser(user);
  }, [user]);

  const handleUpdatePronounName = async () => {
    updateUser(editUser, "pronounName")
      .then(() => {
        setAlert("Data changed successfully");

        //setEditName(false);
      })
      .catch((e) => {
        setEditName(e.message);
      });
  };

  const handleUpdateEmail = async () => {
    if (validator.validate(editUser.email) && editUser.current_password) {
      if (
        await confirm({
          confirmation:
            "IMPORTANT: You will be logged out and you will receive a new verification e-mail.",
          options: { title: "Confirm change e-mail to " + editUser.email + "?" }
        })
      ) {
        firebase
          .auth()
          .signInWithEmailAndPassword(
            firebase.auth().currentUser.email,
            editUser.current_password
          )
          .then(function (userCredential) {
            userCredential.user
              .updateEmail(editUser.email)
              .then(() => {
                updateUser(editUser, "email")
                  .then(() => {
                    setAlert("E-mail changed successfully");
                    setTimeout(() => {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          localStorage.removeItem("@token");
                          history.push({
                            pathname: "/",
                            state: "logout"
                          });
                        });
                    }, 2000);
                  })
                  .catch((e) => {
                    setEditName(e.message);
                  });
              })
              .catch((e) => {
                setEditMail(e.message);
              });
          })
          .catch((e) => {
            setEditMail(e.message);
          });
      } else {
        setEditUser(user);
        setEditMail(false);
      }
    } else {
      setEditMail("Please enter a valid e-mail and your current password");
    }
  };

  const handleUpdatePassword = async () => {
    let fireUser = firebase.auth().currentUser;

    let credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      editUser.current_password
    );

    fireUser
      .reauthenticateWithCredential(credential)
      .then(function () {
        if (!editUser.new_password || editUser.new_password.length < 8)
          throw new Error("Password must be at least 8 characters");
        if (editUser.new_password !== editUser.retype_new_password)
          throw new Error("Passwords should match");

        firebase
          .auth()
          .currentUser.updatePassword(editUser.new_password)
          .then(() => {
            setEditPassword(false);
            setAlert("Password changed successfully");
          })
          .catch((e) => {
            setEditPassword(e.message);
          });
      })
      .catch(function (error) {
        setEditPassword(error.message);
      });
  };

  const handleCloseAccount = async () => {
    if (
      await confirm({
        confirmation:
          "Your account, saved entries, search criteria and personal login data will be permanently deleted.",
        options: { title: "Are you sure?" }
      })
    ) {
      var user = firebase.auth().currentUser;

      user
        .delete()
        .then(function () {
          // User deleted.

          axios
            .delete(process.env.REACT_APP_BACKEND_URL + "/users/delete_current")
            .then(() => {
              setAlert(
                "Your ILGA World Monitor account and your data have been permanently deleted."
              );
              setTimeout(() => {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    localStorage.removeItem("@token");
                    history.push({ pathname: "/", state: "logout" });
                  });
              }, 2000);
            });
        })
        .catch(function (error) {
          // An error happened.
        });
    } else {
    }
  };

  const handleSendData = async () => {
    if (
      await confirm({
        confirmation: "We will send you all the data linked to you",
        options: { title: "Confirm send data?" }
      })
    ) {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/users/request_data")
        .then(() => {
          setAlert("Data request sent");
        });
    } else {
    }
  };

  return (
    <MainLayout showControls={isLoggedIn} setAlert={setAlert} alert={alert}>
      <section class="cards-container terms-and-conditions">
        <h1 class="terms-and-conditions__title">My Profile</h1>
        <div class="card card--100">
          <div class="card-row">
            <b class="card-row__header">
              <img
                src="/assets/svgs/perm_identity.svg"
                className="my-profile-icon"
              />{" "}
              Your pronoun(s) and name(s)
            </b>
            {!editName && (
              <p class="card-row__text">
                {editUser.pronoun} - {editUser.firstname} {editUser.lastname}{" "}
                <img
                  onClick={(e) => setEditName(true)}
                  className="profile-edit-btn"
                  src="/assets/svgs/edit.svg"
                />
              </p>
            )}
            {editName && (
              <p class="card-row__text profile-edit">
                {editName !== true && (
                  <div className="profile-error">
                    {editName}
                    <br />
                    <br />
                  </div>
                )}
                Pronoun(s): <br />
                <input
                  class="profile-text"
                  type="text"
                  value={editUser.pronoun}
                  onChange={(e) =>
                    setEditUser({ ...editUser, pronoun: e.target.value })
                  }
                />{" "}
                <br />
                First Name: <br />
                <input
                  class="profile-text"
                  type="text"
                  value={editUser.firstname}
                  onChange={(e) =>
                    setEditUser({ ...editUser, firstname: e.target.value })
                  }
                />{" "}
                <br />
                Last Name(s): <br />
                <input
                  class="profile-text"
                  type="text"
                  value={editUser.lastname}
                  onChange={(e) =>
                    setEditUser({ ...editUser, lastname: e.target.value })
                  }
                />
                <br />
                <button
                  class="profile-save-btn"
                  onClick={(e) =>
                    handleUpdatePronounName().then(() => setAlert("Nanana"))
                  }
                >
                  Save
                </button>
                <button
                  class="profile-cancel-btn"
                  onClick={(e) => {
                    setEditUser(user);
                    setEditName(false);
                  }}
                >
                  Cancel
                </button>
              </p>
            )}
          </div>
          <div class="card-row">
            <b class="card-row__header">
              <img src="/assets/svgs/email.svg" className="my-profile-icon" />{" "}
              Your e-mail
            </b>
            {!editMail && (
              <p class="card-row__text">
                {editUser.email}{" "}
                <img
                  onClick={(e) => setEditMail(true)}
                  className="profile-edit-btn"
                  src="/assets/svgs/edit.svg"
                />
              </p>
            )}
            {editMail && (
              <p class="card-row__text profile-edit">
                If you are subscribed to weekly digests, they will be sent to
                your new email account as of next Tuesday at 16h GMT
                <br />
                <br />
                {editMail !== true && (
                  <div className="profile-error">
                    {editMail}
                    <br />
                    <br />
                  </div>
                )}
                E-mail: <br />
                <input
                  class="profile-text"
                  type="text"
                  value={editUser.email}
                  onChange={(e) =>
                    setEditUser({ ...editUser, email: e.target.value })
                  }
                />{" "}
                <br />
                Confirm your password: <br />
                <input
                  class="profile-text"
                  type="password"
                  value={editUser.current_password}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      current_password: e.target.value
                    })
                  }
                />{" "}
                <br />
                <button
                  class="profile-save-btn"
                  onClick={(e) => {
                    if (editUser.email !== user.email) handleUpdateEmail();
                  }}
                >
                  Save
                </button>
                <button
                  class="profile-cancel-btn"
                  onClick={(e) => {
                    setEditUser(user);
                    setEditMail(false);
                  }}
                >
                  Cancel
                </button>
              </p>
            )}
          </div>
          <div class="card-row">
            <b class="card-row__header">
              <img
                src="/assets/svgs/lock_outline.svg"
                className="my-profile-icon"
              />{" "}
              Your password
            </b>
            {!editPassword && (
              <p class="card-row__text">
                ********************
                <img
                  onClick={(e) => setEditPassword(true)}
                  className="profile-edit-btn"
                  src="/assets/svgs/edit.svg"
                />
              </p>
            )}
            {editPassword && (
              <p class="card-row__text profile-edit">
                {editPassword !== true && (
                  <div className="profile-error">
                    {editPassword}
                    <br />
                    <br />
                  </div>
                )}
                Current Password: <br />
                <input
                  class="profile-text"
                  type="password"
                  value={editUser.current_password}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      current_password: e.target.value
                    })
                  }
                />{" "}
                <br />
                New Password: <br />
                <input
                  class="profile-text"
                  type="password"
                  value={editUser.new_password}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      new_password: e.target.value
                    })
                  }
                />{" "}
                <br />
                Retype New Password: <br />
                <input
                  class="profile-text"
                  type="password"
                  value={editUser.retype_new_password}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      retype_new_password: e.target.value
                    })
                  }
                />{" "}
                <br />
                <button
                  class="profile-save-btn"
                  onClick={(e) => {
                    handleUpdatePassword();
                  }}
                >
                  Save
                </button>
                <button
                  class="profile-cancel-btn"
                  onClick={(e) => {
                    setEditUser(user);
                    setEditPassword(false);
                  }}
                >
                  Cancel
                </button>
              </p>
            )}
          </div>
          <div class="card-row">
            <b class="card-row__header">
              <img
                src="/assets/svgs/highlight_off.svg"
                className="my-profile-icon"
              />{" "}
              Delete my account
            </b>
            <p class="card-row__text">
              <a href="#" onClick={handleCloseAccount}>
                Click here to delete your account
              </a>
            </p>
          </div>
          <div class="card-row">
            <b class="card-row__header">
              <img
                src="/assets/svgs/attachment.svg"
                className="my-profile-icon"
              />{" "}
              Send me all my data
            </b>
            <p class="card-row__text">
              <a href="#" onClick={handleSendData}>
                Click here to receive an email with all the personal data we
                have on you
              </a>
            </p>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user, type) => dispatch(updateUser(user, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
