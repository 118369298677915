import axios from "axios";

export const getLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/languages"
      );

      dispatch({ type: "GET_LANGUAGES", payload: responseData.data.languages });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSourceNames = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/sources_names"
      );

      dispatch({
        type: "GET_SOURCES_NAMES",
        payload: responseData.data.sources_names
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSourceTypes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/source_types"
      );

      dispatch({
        type: "GET_SOURCE_TYPES",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getSourceStatuses = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/source_statuses"
      );

      dispatch({
        type: "GET_SOURCE_STATUSES",
        payload: responseData.data.source_statuses
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getJurisdictions = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/jurisdictions"
      );

      dispatch({
        type: "GET_JURISDICTIONS",
        payload: responseData.data.jurisdictions
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getRegions = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/regions"
      );

      dispatch({
        type: "GET_REGIONS",
        payload: responseData.data.regions
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getTags = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/tags"
      );

      dispatch({
        type: "GET_TAGS",
        payload: responseData.data.tags
      });
      dispatch({ type: "LOADING_TAGS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};

export const getSyntaxTypes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/syntax_types"
      );

      dispatch({
        type: "GET_SYNTAX_TYPES",
        payload: responseData.data.syntax_types
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getAlertSubaccounts = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/alert_subaccounts"
      );

      dispatch({
        type: "GET_ALERT_SUBACCOUNTS",
        payload: responseData.data.alert_subaccounts
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getLanguageSyntaxes = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/language_syntaxes"
      );

      dispatch({
        type: "GET_LANGUAGE_SYNTAXES",
        payload: responseData.data.language_syntaxes
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getReportReasons = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_RELATED", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/related/report_reasons"
      );
      dispatch({
        type: "GET_REPORT_REASONS",
        payload: responseData.data.report_reasons
      });
      dispatch({ type: "LOADING_RELATED", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_RELATED", payload: false });
    }
  };
};

export const getJurisdictionStats = (jur_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_JUR_STATS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/stats/jurisdiction_stats/" +
          jur_id
      );

      dispatch({
        type: "GET_JURISDICTIONS_STATS",
        payload: { jurisdiction_id: jur_id, stats: responseData.data }
      });
      dispatch({ type: "LOADING_JUR_STATS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_JUR_STATS", payload: false });
    }
  };
};



export const getTagStats = (tag_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAG_STATS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/stats/tag_stats/" + tag_id
      );

      dispatch({
        type: "GET_TAG_STATS",
        payload: { tag_id: tag_id, stats: responseData.data }
      });
      dispatch({ type: "LOADING_TAG_STATS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_TAG_STATS", payload: false });
    }
  };
};


export const getLanguageStats = (language_id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_LANGUAGE_STATS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/stats/language_stats/" +
          language_id
      );

      dispatch({
        type: "GET_LANGUAGE_STATS",
        payload: { language_id: language_id, stats: responseData.data }
      });
      dispatch({ type: "LOADING_LANGUAGE_STATS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_RELATED", payload: err });
      dispatch({ type: "LOADING_LANGUAGE_STATS", payload: false });
    }
  };
};

