import * as clipboard from "clipboard-polyfill";
import { utc } from "moment-timezone";
import axios from "axios";

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str.replace(/(<([^>]+)>)/gi, ""));
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

export const citeOne = async (article, jurisdictions) => {
  const cite =
    '“<a href="' +
    article.u +
    '">' +
    article.h +
    "</a>”" +
    (article.th ? " [" + article.th + "], " : ", ") +
    "<em>" +
    article.s +
    "</em>, " +
    utc(article.da).format("DD MMMM YYYY") +
    ".";

  copyToClip(cite);
  return cite;
};

export const citeMultiple = async (articles, jurisdictions) => {
  let cite = "";
  articles.forEach((article) => {
    cite +=
      (cite ? "<p>" : "<p>") +
      '“<a href="' +
      article.u +
      '">' +
      article.h +
      "</a>”" +
      (article.th ? " [" + article.th + "], " : ", ") +
      "<em>" +
      article.s +
      "</em>, " +
      utc(article.da).format("DD MMMM YYYY") +
      ".</p>";
  });
  

  copyToClip(cite);
};
