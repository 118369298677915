import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactWordcloud from "react-wordcloud";
import { tippy } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { useRef } from "react";
import axios from "axios";
import SVG from "react-inlinesvg";

const TagStats = ({
  tags,
  alwaysReload,
  slice,
  gotoSearch,
  setCurrentSearch,
  currentSearch
}) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [reloadTags, setReloadTags] = useState(false);
  let node = useRef(null);

  let colors = [
    "#577590",
    "#4D908E",
    "#43AA8B",
    "#90BE6D",
    "#F9C74F",
    "#F8961E",
    "#F3722C",
    "#F94144"
  ];

  useEffect(() => {
    console.log("L STATS");
  }, []);

  useEffect(() => {
    if (data.length) return;
    console.log("aaa");
    let total = tags.reduce((total, obj) => total + obj.n_approved, 0);
    let official = tags
      .filter((t) => [33, 32, 77, 126, 50, 8, 139, 140].indexOf(t.id) === -1)
      .map((l, i) => ({
        id: l.name,
        text: l.name,
        value: Math.round(l.n_approved),
        color: colors[1]
      }))
      .sort((a, b) => b.value - a.value)
      //.slice(0, 100)
      .map((l, i) => ({
        ...l,
        color: colors[i]
      }));
    if (slice) official = official.slice(0, slice);
    console.log("official", official);
    setData(official);
  }, [tags]);

  useEffect(() => {
    console.log("bbbb", reloadTags);
    if (reloadTags)
      setTimeout(() => {
        if (document.getElementById("cloud")) {
          document.getElementById("cloud").childNodes[0].xmlns =
            "http://www.w3.org/2000/svg";
          axios.post(process.env.REACT_APP_BACKEND_URL + "/stats/saveSvg", {
            svg: document.getElementById("cloud").childNodes[0].innerHTML
          });
        }
      }, 5000);
  }, [reloadTags]);

  let lastColor = "#fff";
  const options = {
    colors: [
      "#577590",
      "#4D908E",
      "#43AA8B",
      "#90BE6D",
      "#F8961E",
      "#F3722C",
      "#F94144"
    ],
    enableTooltip: true,
    deterministic: false,
    fontSizes: [12, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 5,
    rotations: 1,
    rotationAngles: [0],
    transitionDuration: 0,

    enableOptimizations: true,
    svgAttributes: {
      viewBox: "0 0 600 400",

      onLoad: () => {
        var xlinkns = "http://www.w3.org/1999/xlink";
        //console.log(document.getElementsByClassName("cloud-svg-wrapper")[0]);
        if (
          document.getElementsByClassName("cloud-svg-wrapper")[0] &&
          document
            .getElementsByClassName("cloud-svg-wrapper")[0]
            .getElementsByTagName("svg")[0]
        ) {
          console.log(
            document
              .getElementsByClassName("cloud-svg-wrapper")[0]
              .getElementsByTagName("svg")[0]
          );
          document
            .getElementsByClassName("cloud-svg-wrapper")[0]
            .getElementsByTagName("svg")[0]
            .setAttributeNS(xlinkns, "width", "1000px");
          document
            .getElementsByClassName("cloud-svg-wrapper")[0]
            .getElementsByTagName("svg")[0]
            .setAttributeNS(xlinkns, "height", "1000px");

        }
      }
    }
  };
  const size = [550, 400];
  const callbacks = {
    onWordClick: (word) =>
      gotoSearch
        ? setCurrentSearch({
            ...currentSearch,
            tags: [tags.find((t) => t.name === word.text)]
          }).then(() => history.push("/search-results"))
        : history.push(
            "/topics/" + tags.find((t) => t.name === word.text).slug
          ),
    //onWordMouseOver: ,
    getWordTooltip: (word) => {
      return `${word.text} (${word.value} entries)`;
    }
  };
  return (
    <div className="cloud-svg-main " id="cloud">
      <div className="cloud-svg-wrapper" ref={node}>
        {data.length > 0 && (reloadTags || alwaysReload) ? (
          <Fragment>
            <ReactWordcloud
              words={data}
              callbacks={callbacks}
              options={options}
              size={size}
              onLoad={() => alert("e")}
            />
          </Fragment>
        ) : (
          <SVG
            onError={(e) => {
              console.log(e);
              console.log(
                "/assets/svgs/tags/tags_" +
                  new Date().toISOString().split("T")[0] +
                  ".svg"
              );
              setReloadTags(true);
            }}
            src={
              "/assets/svgs/tags/tags_" +
              new Date().toISOString().split("T")[0] +
              ".svg"
            }
            preloader={
              <div className="LoadingBar">
                <div className="LoadingBar-progress"></div>
              </div>
            }
            onLoad={(src, hasCache) => {
              console.log(hasCache);
              tippy("svg text", {
                content: "I'm a Tippy tooltip!"
              });
              console.log(src);
            }}
          ></SVG>
        )}
      </div>
    </div>
  );
};

export default React.memo(TagStats);
