import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  createRef
} from "react";
import ReactTooltip from "react-tooltip";
import useInterval from "./useInterval";
export const AutoScroller = ({
  children,
  speed,
  initial,
  classes,
  subclasses,
  pauseOver,
  unit,
  subclassesFn
}) => {
  const [elRefs, setElRefs] = React.useState([]);
  const [items, setItems] = useState(children.slice(0, initial));
  const [last, setLast] = useState(1);
  const [pause, setPause] = useState(false);
  let mainDiv = useRef();

  React.useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(children.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [items.length]);

  useInterval(() => {
    if (!pause) {
      setItems(
        children.slice(last, initial + last).concat(children.slice(0, initial))
      );

      if (last < children.length - initial) {
        mainDiv.current.style.marginTop =
          (unit ? -unit * last : -5 * last - 1) + "rem";
        setLast((last) => last + 1);
      } else {
        setItems(children.slice(0, initial));
        setLast(1);
      }
    }
  }, speed + speed * Math.random());

  return (
    <div className={`${classes} autoscroll-container`}>
      <div ref={mainDiv} style={{ transition: "all 1s ease" }}>
        {children.concat(children.slice(0, 5)).map((el, i) => (
          <div
            ref={elRefs[i]}
            class={
              (subclasses ? subclasses : "") +
              " " +
              (subclassesFn && subclassesFn(i, last))
            }
            key={i}
          >
            {React.cloneElement(el, {
              key: el,
              onMouseEnter: (e) => setPause(true),
              onMouseLeave: (e) => setPause(false)
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
