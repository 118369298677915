import { utc } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { confirm } from "../Components/Confirm";
import Entry from "../Components/Entry";
import ReportPopup from "../Components/ReportPopup";
import MainLayout from "../Layout/MainLayout";
import {
  getJurisdictions,
  getLanguages,
  getReportReasons,
  getSourceNames,
  getSourceTypes,
  getTags
} from "../redux/actions/related";
import {
  deleteSavedFolder,
  executeSearch,
  getSavedFolders,
  removeFromSavedFolder,
  resetCurrentSearch,
  setArticleUsed,
  setCurrentSearch,
  toggleDaughters
} from "../redux/actions/search";

const MyFolders = ({
  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  loading_tags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  toggleDaughters,
  loading_daughters,
  executeSearch,
  results,
  savedFolders,
  getSavedFolders,
  deleteSavedFolder,
  removeFromSavedFolder,
  report_reasons,
  user,
  getReportReasons,
  setArticleUsed
}) => {
  const [res, setRes] = useState([]);
  const [alert, setAlert] = useState(null);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [reportArticle, setReportArticle] = useState(null);
  const [articlesSelected, setArticlesSelected] = useState([]);
  const [currentGroups, setCurrentGroups] = useState([]);
  const addArticlesSelected = (article) => {
    setArticlesSelected([...articlesSelected, article]);
  };

  const removeArticlesSelected = (article) => {
    setArticlesSelected(articlesSelected.filter((a) => a.id !== article.id));
  };
  useEffect(() => {
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!sourceNames || !sourceNames.length) getSourceNames();
    if ((!tags || !tags.length) && !loading_tags) getTags();
    if (!sourceTypes || !sourceTypes.length) getSourceTypes();
    if (!savedFolders || !savedFolders.length) getSavedFolders();

    if (!report_reasons || !report_reasons.length) getReportReasons();
  }, []);

  useEffect(() => {
    if (savedFolders && savedFolders.length > 0 && currentGroups.length === 0) {
      setCurrentGroups([savedFolders[0].id]);
    }
  }, [savedFolders]);

  const handleDelete = async (sf) => {
    if (
      await confirm({
        confirmation: "Are you sure?",
        options: { title: "Deleting " + sf.name + " folder" }
      })
    ) {
      deleteSavedFolder(sf.id).then(() => setAlert("Saved Folder Deleted"));
    } else {
    }
  };

  const handleRemoveArticle = async (sf, article_id) => {
    if (
      await confirm({
        confirmation: "Are you sure?",
        options: { title: "Remove this article from " + sf.name + " folder" }
      })
    ) {
      removeFromSavedFolder(sf.id, article_id).then(() =>
        setAlert("Article removed")
      );
    } else {
    }
  };

  return (
    <MainLayout
      showControls={true}
      alert={alert}
      setAlert={setAlert}
      articlesSelected={articlesSelected}
      sharePopupOpen={sharePopupOpen}
      setSharePopupOpen={setSharePopupOpen}
    >
      <ReportPopup
        reportReasons={report_reasons}
        reportArticle={reportArticle}
        setReportArticle={setReportArticle}
        user={user}
      />
      <section className="saved-search-criteria">
        <h1 className="saved-search-criteria__title">My Folders</h1>

        {savedFolders &&
          savedFolders.length > 0 &&
          sourceTypes.length > 0 &&
          jurisdictions.length > 0 && (
            <div className="saved-search-criteria__subtitle">
              Hello {user.firstname}. You currently have {savedFolders.length}{" "}
              folders with a total of{" "}
              {savedFolders.reduce(
                (acc, v) => acc + (v.articles.length || 0),
                0
              )}{" "}
              saved entries.
              <br />
              Please note that the ILGA World Monitor does not store third party
              content. This function allows you to save the reference to the
              original source. If the original source is lost or moved, the
              reference will no longer be valid.
            </div>
          )}

        {savedFolders &&
        savedFolders.length > 0 &&
        sourceTypes.length > 0 &&
        jurisdictions.length > 0 ? (
          savedFolders.map((sf) => (
            <div className="search-criteria-container" key={sf.id}>
              <div className="search-criteria-accordion">
                <label
                  className="search-criteria-accordion__title"
                  for="criteria1"
                  onClick={(e) => {
                    currentGroups.indexOf(sf.id) !== -1
                      ? setCurrentGroups(
                          currentGroups.filter((cg) => cg !== sf.id)
                        )
                      : setCurrentGroups([...currentGroups, sf.id]);
                  }}
                >
                  {sf.name} ({sf.articles.length})
                </label>
                <span className="saved-search-date">
                  Updated: {utc(sf.updated_at).format("DD MMM YYYY")}
                </span>
              </div>
              <div className="search-criteria-accordion__notifications">
                <button
                  className="search-criteria-accordion__notification search-criteria-accordion__notification--delete"
                  onClick={(e) => handleDelete(sf)}
                ></button>
              </div>

              <label
                className="search-criteria-accordion__toggle"
                for="criteria1"
                onClick={(e) => {
                  currentGroups.indexOf(sf.id) !== -1
                    ? setCurrentGroups(
                        currentGroups.filter((cg) => cg !== sf.id)
                      )
                    : setCurrentGroups([...currentGroups, sf.id]);
                }}
              >
                {currentGroups.indexOf(sf.id) !== -1 ? "-" : "+"}
              </label>

              <div
                className="search-criteria-accordion-content search-criteria-accordion-content--folders"
                style={{
                  ...(currentGroups.indexOf(sf.id) !== -1
                    ? { display: "flex" }
                    : { display: "none" })
                }}
              >
                {sf.articles.map(
                  (a, key) =>
                    !a.deleted && (
                      <Entry
                        key={key}
                        article={a}
                        languages={languages}
                        jurisdictions={jurisdictions}
                        sourceNames={sourceNames}
                        sourceTypes={sourceTypes}
                        tags={tags}
                        toggleDaughters={toggleDaughters}
                        loading_daughters={loading_daughters}
                        noselect={true}
                        setAlert={setAlert}
                        fromFolders={sf.id}
                        setArticleUsed={setArticleUsed}
                        removeFromSavedFolder={(article_id) =>
                          handleRemoveArticle(sf, article_id)
                        }
                        setSharePopupOpen={setSharePopupOpen}
                        reportArticle={reportArticle}
                        setReportArticle={setReportArticle}
                        addArticlesSelected={addArticlesSelected}
                        removeArticlesSelected={removeArticlesSelected}
                        setArticlesSelected={setArticlesSelected}
                      />
                    )
                )}
              </div>
            </div>
          ))
        ) : savedFolders && savedFolders.length === 0 ? (
          "No folders saved"
        ) : (
          <div class="loading-gif">
            <img src="/assets/img/loading.gif" />
          </div>
        )}
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  results: state.search.results,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,
  tags: state.related.tags,
  loading_daughters: state.search.loading_daughters,
  loading_tags: state.related.loading_tags,
  savedFolders: state.search.savedFolders,
  report_reasons: state.related.report_reasons,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),
  getTags: () => dispatch(getTags()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  toggleDaughters: (article, group) =>
    dispatch(toggleDaughters(article, group)),
  executeSearch: (search) => dispatch(executeSearch(search)),
  getSavedFolders: () => dispatch(getSavedFolders()),
  deleteSavedFolder: (id) => dispatch(deleteSavedFolder(id)),
  setArticleUsed: (folder_id, id, used) =>
    dispatch(setArticleUsed(folder_id, id, used)),
  removeFromSavedFolder: (id, article_id) =>
    dispatch(removeFromSavedFolder(id, article_id)),
  getReportReasons: () => dispatch(getReportReasons())
});
export default connect(mapStateToProps, mapDispatchToProps)(MyFolders);
