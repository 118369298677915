import React, { useState, useContext, useRef, useEffect } from "react";

import PublicHomeLayout from "./Layout/PublicHomeLayout";



import { AuthContext } from "./index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getWorldStats } from "./redux/actions/articles";
import { getJurisdictions, getRegions } from "./redux/actions/related";
import { Fragment } from "react";

const Test = ({
  all_number,
  regions,
  getRegions,
  getWorldStats,
  jurisdictions,
  getJurisdictions
}) => {
  useEffect(() => {
    if (!regions || !regions.length) getRegions();
    if (!all_number) getWorldStats();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    console.log(all_number);
  }, [all_number]);

  return (
    <Fragment showControls={false}>
      <div className="public-home">
        <div className="cards-container cards-container--public-home">
          <div className="card card--100 live-numbers">
            <div className="live-numbers__header">
              <h2>{all_number}</h2>
              <b>World total</b>
            </div>
            {jurisdictions && jurisdictions.length > 0 && (
              <ul className="live-numbers__list">
                <li className="live-numbers__list-item live-numbers__list-item--green">
                  <p>{jurisdictions[0].name}</p>
                  <b>3218</b>
                </li>
                <li className="live-numbers__list-item live-numbers__list-item--purple">
                  <p>Europe & Central Asia</p>
                  <b>3218</b>
                </li>
                <li className="live-numbers__list-item live-numbers__list-item--yellow">
                  <p>Africa</p>
                  <b>3218</b>
                </li>
                <li className="live-numbers__list-item live-numbers__list-item--red">
                  <p>Asia</p>
                  <b>3218</b>
                </li>
                <li className="live-numbers__list-item live-numbers__list-item--orange">
                  <p>North America</p>
                  <b>3218</b>
                </li>
                <li className="live-numbers__list-item live-numbers__list-item--blue">
                  <p>Oceania</p>
                  <b>3218</b>
                </li>
              </ul>
            )}
          </div>
          <div className="card card--100 public-home-text">
            <h3>International SOGIESC reporting, all under one roof.</h3>
            <p>
              The ILGA World Monitor keeps track of events relevant to our
              communities around the globe, as reported by local, regional and
              international media outlets, civil society organisations,
              governmental agencies, human rights bodies, academia, and other
              relevant stakeholders.
              <br />
              <br />
              This project will provide our Research Team, and you, with an
              easily accessible and truly expansive collection of digital
              content about sexual orientation, gender identity and expression,
              and sex characteristics. This tool allows users to quickly scan
              for relevant developments in areas of particular interest, search
              for articles pertaining to specific countries, identities or
              events, or zoom out and view trends from a broader, more holistic
              perspective.
              <br />
              <br />
              The internet is vast and can at times feel like a maze, but with
              the ILGA World Monitor research and learning about our communities
              is easier than ever. After all,{" "}
              <b>an informed community is a strong community.</b>
            </p>
          </div>
        </div>

        <div className="updates-container">
          <div className="update update--low">1</div>
          <div className="update update--mid">2</div>
          <div className="update update--high">3</div>
          <div className="update update--mid">4</div>
          <div className="update update--low">5</div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  all_number: state.articles.stats.all,
  regions: state.related.regions,
  jurisdictions: state.related.jurisdictions
});

const mapDispatchToProps = (dispatch) => ({
  getWorldStats: () => dispatch(getWorldStats()),
  getRegions: () => dispatch(getRegions()),
  getJurisdictions: () => dispatch(getJurisdictions())
});

export default connect(mapStateToProps, mapDispatchToProps)(Test);
