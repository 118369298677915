import { utc } from "moment-timezone";

const initialState = {
  results: [],
  current: {
    countries: [],
    tags: [],
    languages: [],
    sources: [],
    source_types: [],
    source_locations: [],
    query: "",
    from: utc().subtract(1, "month"),
    to: utc(),
    sortBy: "dd",
    groupBy: "st"
  },
  error: null,
  loading: false,
  saving: false,
  saved: false,
  loading_daughters: null,
  filterPendingExecution: false,
  savedSearches: null,
  savedFolders: null
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_SEARCH":
      return {
        ...state,
        current: { ...state.current, ...action.payload.search },
        ...(action.payload.filter && { filterPendingExecution: true })
      };
    case "RESET_CURRENT_SEARCH":
      return {
        ...initialState,
        current: {
          ...initialState.current,
          from: state.current.from,
          to: state.current.to,
          sortBy: state.current.sortBy,
          groupBy: state.current.groupBy
        }
      };
    case "EXECUTE_SEARCH":
      let results = {};
      switch (state.current.groupBy) {
        case "st":
          action.payload.forEach((a) => {
            if (!results[a.st]) results[a.st] = [a];
            else results[a.st].push(a);
          });

          break;
        case "l":
          action.payload.forEach((a) => {
            if (!results[a.l]) results[a.l] = [a];
            else results[a.l].push(a);
          });

          break;
        case "c":
          action.payload.forEach((a) => {
            if (!results[a.c]) results[a.c] = [a];
            else results[a.c].push(a);
          });

          break;
        case "j":
          action.payload.forEach((a) => {
            if (a.j)
              a.j.split(",").forEach((j_id) => {
                if (!results[j_id]) results[j_id] = [a];
                else results[j_id].push(a);
              });
            else {
              if (!results[a.c]) results[a.c] = [a];
              else results[a.c].push(a);
            }
          });

          break;
        case "r":
          let regions = {
            AFR: "Africa",
            ASI: "Asia",
            EUR: "Europe",
            LAT: "Latin America & Caribbean ",
            NAM: "North America",
            OCE: "Oceania"
          };
          action.payload.forEach((a) => {
            if (a.r)
              a.r.split(",").forEach((r_id) => {
                if (r_id) {
                  if (!results[regions[r_id]]) results[regions[r_id]] = [a];
                  else results[regions[r_id]].push(a);
                } else {
                  if (!results["Other"]) results["Other"] = [a];
                  else results["Other"].push(a);
                }
              });
            else {
              console.log(a);
              if (a.cr) {
                if (!results[regions[a.cr]]) results[regions[a.cr]] = [a];
                else results[regions[a.cr]].push(a);
              } else {
                if (!results["Other"]) results["Other"] = [a];
                else results["Other"].push(a);
              }
            }
          });

          break;
        case "ir":
          action.payload.forEach((a) => {
            if (a.ir)
              a.ir.split(",").forEach((ir_id) => {
                if (ir_id) {
                  if (!results[ir_id]) results[ir_id] = [a];
                  else results[ir_id].push(a);
                } else {
                  if (!results["OTHER"]) results["OTHER"] = [a];
                  else results["OTHER"].push(a);
                }
              });
            else {
              if (a.cir) {
                if (!results[a.cir]) results[a.cir] = [a];
                else results[a.cir].push(a);
              } else {
                if (!results["OTHER"]) results["OTHER"] = [a];
                else results["OTHER"].push(a);
              }
            }
          });

          break;
        default:
          break;
      }
      let resultsTmp = Object.entries(results).sort((a, b) => b[1].length - a[1].length);
      let resultsSorted = {};
      resultsTmp.forEach((e) => (resultsSorted[e[0]] = e[1]));
      return {
        ...state,
        results: resultsSorted,
        saved: false,
        filterPendingExecution: false
      };
    case "SHOW_DAUGHTERS":
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.group]: state.results[action.payload.group].map((a) =>
            a.id === action.payload.mother
              ? {
                  ...a,
                  showDaughters: !a.showDaughters
                }
              : a
          )
        }
      };
    case "FILTER_PENDING_FALSE":
      return {
        ...state,
        filterPendingExecution: false
      };
    case "LOAD_SHOW_DAUGHTERS":
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.group]: state.results[action.payload.group].map((a) =>
            a.id === action.payload.mother
              ? {
                  ...a,
                  showDaughters: true,
                  daughters: action.payload.daughters
                }
              : a
          )
        }
      };
    case "FETCH_DAUGHTERS":
      return {
        ...state,
        results: state.results.map((a) =>
          a.id === action.payload.mother ? { ...a, daughters: action.payload.daughters } : a
        )
      };
    case "ERROR_SEARCH":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" + tmpError.list.map((error) => "<li>" + error + "</li>").join("") + "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response ? tmpError.response.data.message : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "GET_SAVED_SEARCHES":
      return { ...state, savedSearches: action.payload };
    case "ADD_SAVED_SEARCH":
      return {
        ...state,
        savedSearches: [action.payload, ...state.savedSearches]
      };
    case "UPDATE_SAVED_SEARCH":
      return {
        ...state,
        savedSearches: [action.payload, ...state.savedSearches.filter((ss) => ss.id !== action.payload.id)].sort(
          (a, b) => {
            if (a.updated_at > b.updated_at) return -1;
            else if (a.updated_at < b.updated_at) return 1;
            else if (a.id > b.id) return -1;
            else if (a.id < b.id) return 1;
            else return 0;
          }
        )
      };

    case "DELETE_SAVED_SEARCH":
      return {
        ...state,
        savedSearches: state.savedSearches.filter((ss) => ss.id !== action.payload.id)
      };

    case "STOP_SAVED_SEARCH":
      return {
        ...state,
        savedSearches: [action.payload, ...state.savedSearches.filter((ss) => ss.id !== action.payload.id)].sort(
          (a, b) => {
            if (a.updated_at > b.updated_at) return -1;
            else if (a.updated_at < b.updated_at) return 1;
            else if (a.id > b.id) return -1;
            else if (a.id < b.id) return 1;
            else return 0;
          }
        )
      };

    case "DELETE_SAVED_FOLDER":
      return {
        ...state,
        savedFolders: state.savedFolders.filter((ss) => ss.id !== action.payload.id)
      };
    case "GET_SAVED_FOLDERS":
      return { ...state, savedFolders: action.payload };
    case "ADD_SAVED_FOLDER":
      return {
        ...state,
        savedFolders: [action.payload, ...state.savedFolders]
      };
    case "UPDATE_SAVED_FOLDER":
      return {
        ...state,
        savedFolders: [action.payload, ...state.savedFolders.filter((ss) => ss.id !== action.payload.id)].sort(
          (a, b) => {
            if (a.updated_at > b.updated_at) return -1;
            else if (a.updated_at < b.updated_at) return 1;
            else if (a.id > b.id) return -1;
            else if (a.id < b.id) return 1;
            else return 0;
          }
        )
      };

    case "DELETE_FROM_SAVED_FOLDER":
      return {
        ...state,
        savedFolders: state.savedFolders.map((sf) =>
          sf.id === action.payload.id
            ? {
                ...sf,
                articles: sf.articles.map((a) => (a.id === action.payload.article_id ? { ...a, deleted: true } : a))
              }
            : sf
        ) /*[
          action.payload,
          ...state.savedFolders.filter((ss) => ss.id !== action.payload.id)
        ].sort((a, b) => {
          if (a.updated_at > b.updated_at) return -1;
          else if (a.updated_at < b.updated_at) return 1;
          else if (a.id > b.id) return -1;
          else if (a.id < b.id) return 1;
          else return 0;
        })*/
      };

    case "CLEAR_ERROR_SEARCH":
      return { ...state, error: null };
    case "LOADING_SEARCH":
      return { ...state, loading: action.payload };
    case "SAVING_SEARCH":
      return { ...state, saving: action.payload };
    case "SAVE_SEARCH":
      return { ...state, saved: action.payload };
    case "LOADING_DAUGHTERS":
      return { ...state, loading_daughters: action.payload };
    default:
      return state;
  }
};

export default search;
